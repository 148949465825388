import {makeStyles} from '@material-ui/core/styles';

// @ts-ignore
const ResultStyles = makeStyles(theme => ({
    container: {
        position: "absolute",
        width: "100%",
        height: "auto",
        minHeight: "100%",
        top: 0,
        background: "#FFF9E7",
        left: 0,
        padding: "25px",
        '@media print': {
            padding: 0,
            '& a': {
                display: "none"
            }
        }
    },
    hideOnPrint: {
        marginBottom: "25px",
        '@media print': {
            display: "none"
        }
    },
    headerLogo: {
        textAlign: "center",
        margin: "25px",
        borderBottom: "1px solid rgba(0,0,0,0.2)",
        paddingBottom: "25px"
    },
    certificate: {
        border: "2px solid rgba(0,0,0,0.2)",
        width: "100%",
        maxWidth: "1024px",
        margin: 'auto',
        padding: "15px",
        paddingBottom: "25px",
        position: "relative"
    },
    certificateFooter: {
        marginTop: "35px",
        display: "flex",
        width: "100%",
        alignItems: "baseline",
        justifyContent: "space-evenly"
    },
    insetBorder: {
        position: "absolute",
        width: "35px",
        height: "25px",
        '&:first-child': {
            left: "10px",
            top: "10px",
            borderTop: "2px solid rgba(0,0,0,0.2)",
            borderLeft: "2px solid rgba(0,0,0,0.2)"
        },
        '&:nth-child(2)': {
            borderTop: "2px solid rgba(0,0,0,0.2)",
            borderRight: "2px solid rgba(0,0,0,0.2)",
            right: "10px",
            top: "10px",
        },
        '&:nth-child(3)': {
            borderBottom: "2px solid rgba(0,0,0,0.2)",
            borderLeft: "2px solid rgba(0,0,0,0.2)",
            left: "10px",
            bottom: "10px",
        },
        '&:nth-child(4)': {
            borderBottom: "2px solid rgba(0,0,0,0.2)",
            borderRight: "2px solid rgba(0,0,0,0.2)",
            right: "10px",
            bottom: "10px",
        }
    },
    resultTable: {
        width: '50%',
        '@media only screen and (max-width: 600px)': {
            width: "100%"
        },
        '& tr td:first-child': {
            textAlign: "right",
            color: "rgba(0,0,0,0.5)"
        },
        '& tr td:nth-child(2)': {
            paddingLeft: '10px',
            fontWeight: 600,
            maxWidth: "200px"
        }
    },
    signedElement: {
        '@media only screen and (max-width: 600px)': {
            textAlign: "center",
            marginTop: "15px",
            "& p": {
                width: "100%",
                display: "inline-block",
                textAlign: "left",
                paddingBottom: "2px"
            }
        }
    },
    aboveLineTypography: {
        paddingBottom: "10px",
        paddingLeft: "7px",
        fontWeight: 600
    },
    linedTypography: {
        borderTop: "1px solid rgba(0,0,0,0.2)",
        display: "inline",
        paddingTop: "7px",
        margin: "auto",
        fontStyle: "italic",
        color: "rgba(0,0,0,0.5)",
    },
    footer: {
        minHeight: "300px",
        '@media print': {
            minHeight: '150px'
        },
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        maxWidth: "1024px",
        justifyContent: "space-around",
        color: "rgba(0,0,0,0.5)",
        '& > .MuiTypography-body2': {
            paddingLeft: "10px"
        },
        '& > .MuiTypography-body1': {
            textAlign: "center",
            fontStyle: "italic"
        },
        '& .MuiTypography-root': {
            fontSize: '0.95em'
        },
        '& .MuiGrid-item:nth-child(even)': {
            textAlign: "right"
        },
        '& hr': {
            border: "2px solid rgba(0,0,0,0.5)"
        }
    },
    footerContact: {
        "& p": {
            width: "50%",
            display: "inline-block",
        },
        "& p:nth-child(2), p:nth-child(5)": {
            textAlign: "right",
        }
    }


}));

export default ResultStyles;
