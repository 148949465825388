import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {CardNumberElement, CardExpiryElement, CardCVCElement} from "react-stripe-elements";
import Grid from '@material-ui/core/Grid';
import {OrderStyles, PaymentInputStyles} from "../../../styles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Money from "../../util/Money";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import {setOwnerForm, setStripeToken} from '../../../actions';
import {setDisablePlaceOrderButton} from '../../../actions';
import {connect} from "react-redux";
import {useTheme} from "@material-ui/core";
import {getProducts} from "../../util/Api";
import TextField from "@material-ui/core/TextField";
import {validateEmail, validatePhone} from "../../util/Validation";
import Link from "@material-ui/core/Link";

const createOptions = (theme) => {
    return {
        style: {
            base: {
                fontSize: '16px',
                color: theme.palette.primary.main,
                fontFamily: 'Montserrat, sans-serif',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: theme.palette.error.main,
            },
        }
    }
};

const CompleteOrderForm = (props) => {
    const theme = useTheme();
    const paymentInputStyles = PaymentInputStyles();
    const classes = OrderStyles();
    const [data, setData] = React.useState({});
    const [error, setError] = useState(false);
    const [totalAmount, setTotalAmount] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [ownerError, setOwnerError] = useState(true);
    const [stripeError, setStripeError] = useState(true);

    let formHistory = {};
    let errorHistory = {};
    if (sessionStorage.getItem("orderForm")) {
        formHistory = JSON.parse(sessionStorage.getItem("orderForm"));
    }
    if (sessionStorage.getItem("formError")) {
        errorHistory = JSON.parse(sessionStorage.getItem("formError"));
    }

    const [form, setValues] = useState({
        ownerFirstName: formHistory.ownerFirstName || '',
        ownerLastName: formHistory.ownerLastName || '',
        phone: formHistory.phone || '',
        email: formHistory.email || '',
    });

    const [formError, setErrors] = useState({
        ownerFirstName: errorHistory.ownerFirstName !== false,
        ownerLastName: errorHistory.ownerLastName !== false,
        phone: errorHistory.phone !== false,
        email: errorHistory.email !== false,
    });

    const [dirty, setDirty] = useState({
        ownerFirstName: formHistory.ownerFirstName || false,
        ownerLastName: formHistory.ownerLastName || false,
        phone: formHistory.phone || false,
        email: formHistory.email || false,
    });


    const fetchData = async () => {
        const data = await getProducts();
        setData({products: data});
    };

    const checkErrors = () => {
        let foundError = false;
        for (const key in formError) {
            if (formError[key]) {
                foundError = true;
            }
        }
        setOwnerError(foundError);
        props.setDisablePlaceOrderButton(foundError || stripeError);
    };

    useEffect(() => {
        if (data.products && data.products.length > 0) {
            const selectedProducts = data.products.filter((t) => {
                return props.selectedProducts.indexOf(t.id) > -1
            });
            setSelectedProducts(selectedProducts);
            const total = selectedProducts.reduce((a, b) => a + (b.price || 0), 0);
            setTotalAmount(total);
        } else {
            fetchData();
        }
        props.setOwnerForm(form);
        checkErrors();
    }, [data, setTotalAmount, form, props]);


    const handleChange = async (event) => {
        if (!event.complete && !event.empty) {
            setError({
                ...error,
                [event.elementType]: event.error ? event.error.message : false
            });
            props.setDisablePlaceOrderButton(true);
        } else if(event.complete) {
            setError({
                ...error,
                [event.elementType]:false
            });
            await createStripeToken(props.stripe);
        }
    };

    async function createStripeToken(stripe) {
        try {
            let {token} = await stripe.createToken({name: "Name"});
            if(token) {
                props.setStripeToken(token);
                setStripeError(false);
                return props.setDisablePlaceOrderButton(ownerError);
            }
            setStripeError(true);
            return props.setDisablePlaceOrderButton(true);
        } catch (e) {
            console.error(e);
            setStripeError(true);
            return props.setDisablePlaceOrderButton(true);
        }
    }

    const handleOnBlur = (e) => {
        setDirty({
            ...dirty,
            [e.target.name]: true
        });
    };

    const handleInputChange = (e) => {
        if (!e) {
            return;
        }

        if (e.target.value === null) e.target.value = '';
        setValues({
            ...form,
            [e.target.name]: e.target.value
        });
        setErrors({
            ...formError,
            [e.target.name]: e.target.required  && !e.target.value
        });
    };

    const handlePhoneChange = e => {
        handleInputChange(e);
        setErrors({
            ...formError,
            [e.target.name]: !(e.target.value && validatePhone(e.target.value))
        });
    };

    const handleEmailChange = e => {
        handleInputChange(e);
        setErrors({
            ...formError,
            [e.target.name]: !(e.target.value && validateEmail(e.target.value))
        });
    };

    return (
        <React.Fragment>

            <Typography variant="h4" align="center" gutterBottom color="primary" className={classes.pageHeader}>
                Complete Your Order
            </Typography>


            <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h6" align="left" color="primary" className={classes.sectionHeader}>
                        <span>ORDER DETAILS</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid item xs={12} sm={12}>

                        <List disablePadding>
                            {selectedProducts.map((test) => (
                                <ListItem key={test.name} disableGutters style={{borderBottom: "1px solid #f0f0f0"}}>
                                    <ListItemText primary={test.name} className={classes.testConfirmation} disableTypography={true} />
                                    <ListItemSecondaryAction className={classes.testConfirmationPrice}>
                                        <Money value={test.price}/>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                            <ListItem disableGutters style={{borderTop: "1px solid black"}}>
                                <div style={{
                                    color: "rgba(0,0,0,0.5)",
                                    textAlign: "right",
                                    paddingRight: "50px",
                                    fontWeight: "500",
                                    width: "100%"
                                }}>
                                    TOTAL
                                </div>
                                <ListItemSecondaryAction>
                                    <Typography variant="subtitle1" style={{fontWeight: 600}}>
                                        <Money value={totalAmount}/>
                                    </Typography>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>

                    </Grid>
                </Grid>
                <Grid item xs={12} sm={3} className={classes.aside}>
                    <Typography color="primary" variant="body2" style={{fontWeight: 700, marginTop: "15px", marginBottom: "10px", fontSize: "13px"}}>
                        NEED SWABS?
                    </Typography>

                    We recommend the cytology brush available at <Link href="https://ezswabs.com" target="_blank" rel="noopener noreferrer">ezswabs.com</Link>

                </Grid>

                <Grid item xs={12} sm={3}>
                    <Typography variant="h6" color="primary" className={classes.sectionHeader}>
                        <span>ABOUT YOU</span>
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        InputLabelProps={{ required: false }}
                        id="ownerFirstName"
                        name="ownerFirstName"
                        label="First Name"
                        fullWidth
                        value={form.ownerFirstName}
                        onChange={handleInputChange}
                        onBlur={handleOnBlur}
                        helperText={dirty.ownerFirstName && formError.ownerFirstName ? "Please enter your first name" : ""}
                        variant="outlined"
                        error={dirty.ownerFirstName && formError.ownerFirstName}
                        margin="normal"
                    />
                    <TextField
                        required
                        InputLabelProps={{ required: false }}
                        id="ownerLastName"
                        name="ownerLastName"
                        label="Last Name"
                        fullWidth
                        value={form.ownerLastName}
                        onChange={handleInputChange}
                        onBlur={handleOnBlur}
                        helperText={dirty.ownerLastName && formError.ownerLastName ? "Please enter your last name" : ""}
                        variant="outlined"
                        error={dirty.ownerLastName && formError.ownerLastName}
                        margin="normal"
                    />

                    <TextField
                        required
                        InputLabelProps={{ required: false }}
                        id="Phone"
                        name="phone"
                        label="Phone"
                        fullWidth
                        value={form.phone}
                        onChange={handlePhoneChange}
                        onBlur={handleOnBlur}
                        helperText={dirty.phone && formError.phone ? "Please enter a valid phone number" : ""}
                        variant="outlined"
                        error={dirty.phone && formError.phone}
                        margin="normal"
                    />
                    <TextField
                        required
                        InputLabelProps={{ required: false }}
                        id="Email"
                        name="email"
                        label="Email"
                        fullWidth
                        autoComplete="email"
                        onChange={handleEmailChange}
                        onBlur={handleOnBlur}
                        value={form.email}
                        variant="outlined"
                        helperText={dirty.email && formError.email ? "Please enter a valid email" : ""}
                        error={dirty.email && formError.email}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={"auto"} sm={3} />

                <Grid item xs={12} sm={3}>
                    <Typography variant="h6" align="left" color="primary" className={classes.sectionHeader}>
                        <span>PAYMENT INFO</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>

                    <div className={paymentInputStyles.cardField}>
                        <CardNumberElement onChange={handleChange} {...createOptions(theme)} />
                        <div className={paymentInputStyles.error} role="alert">
                            {error.cardNumber}
                        </div>
                    </div>

                    <div className={paymentInputStyles.cardField}>
                        <CardExpiryElement onChange={handleChange} {...createOptions(theme)} />
                        <div className={paymentInputStyles.error} role="alert">
                            {error.cardExpiry}
                        </div>
                    </div>

                    <div className={paymentInputStyles.cardField} style={{width: "50%", display: "inline-block", marginTop: "5px"}}>
                        <CardCVCElement onChange={handleChange} {...createOptions(theme)} />
                        <div className={paymentInputStyles.error} role="alert">
                            {error.cardCvc}
                        </div>
                    </div>

                </Grid>

            </Grid>
        </React.Fragment>

    );
};

const mapStateToProps = state => {
    return {selectedProducts: state.selectedProducts, ownerForm: state.ownerForm};
};

export default connect(
    mapStateToProps,
    {setStripeToken, setDisablePlaceOrderButton, setOwnerForm}
)(CompleteOrderForm);
