import React from 'react';

const Money = (props) => {
    const dollarAmount = (props.value/100).toFixed(2);
    return (
        <span>
            $ {dollarAmount}
        </span>
    );
};

export default Money;
