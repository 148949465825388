import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import YourOrder from './YourOrder';
import CompleteOrderForm from './CompleteOrderForm';
import OrderConfirmationForm from './OrderConfirmationForm';
import { connect } from 'react-redux';
import { Elements, injectStripe, StripeProvider } from "react-stripe-elements";
import {
  clearTestSelection,
  setDisableContineButton,
  setDisablePlaceOrderButton,
  setOrderForm,
  setOrderProcessing, setOwnerForm
} from '../../../actions';
import { setOrderHash } from '../../../actions';
import api, { getProducts } from "../../util/Api";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorMessage from "../../util/ErrorMessage";
import Link from "@material-ui/core/Link";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { ProductStyles } from "../../../styles";

import runtimeEnv from '@mars/heroku-js-runtime-env';
const env = runtimeEnv();
const stripeKey = env.REACT_APP_STRIPE_API_KEY || "pk_test_ggmV8MNO75e4l8EkDiKOLJa600ZQm7PwJF";

const useStyles = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: "100%"
    }
  },
}));

const steps = ['Your Order', 'Complete Order'];

export function Checkout(props) {
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [showHelp, setShowHelp] = React.useState(false);
  const styles = ProductStyles();

  const fetchData = async () => {
    const data = await getProducts();
    setData({ products: data });
  };

  useEffect(() => {
    fetchData();
  }, []);


  async function createOrder(order) {
    let user = {};
    const resp = await api.post("/api/auth", user);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + resp.data.authToken
    }

    return await api.post("/api/orders/createorder", order, {
      headers: headers
    });
  }
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setErrorMessage(false);
      setShowHelp(false);
      props.setDisablePlaceOrderButton(true);
      props.setOrderProcessing(true);
      let description = [];
      let totalAmount = 0;
      if (data) {
        let selectedProducts = data.products.filter((t) => {
          return props.selectedProducts.indexOf(t.id) > -1
        });

        totalAmount = selectedProducts.reduce((a, b) => a + (b.price || 0), 0);
        description = selectedProducts.map(function (test) {
          return (test.name);
        })
      }

      const order = {
        "breed": props.selectedBreed.id,
        "productIds": props.selectedProducts,
        "dogName": props.orderForm.dogName,
        "birthDate": props.orderForm.birthDate ? new Date(props.orderForm.birthDate).toLocaleDateString('en-US') : "",
        "sex": props.orderForm.sex,
        "customBreedName": props.orderForm.breedName,
        "registeredName": props.orderForm.registeredName,
        "registrationId": props.orderForm.registrationId,
        "microchipNumber": props.orderForm.microchipNumber,
        "ownerFirstName": props.ownerForm.ownerFirstName,
        "ownerLastName": props.ownerForm.ownerLastName,
        "phone": props.ownerForm.phone,
        "email": props.ownerForm.email,
        "totalAmount": totalAmount,
        "description": description,
        "stripeToken": props.stripeToken.id
      };

      createOrder(order).then((result) => {
        if (result.data.hash) {
          // setHash(result.data.hash);
          props.setOrderHash(result.data.hash);
          setActiveStep(activeStep + 1);
          sessionStorage.removeItem("orderForm");
          sessionStorage.removeItem("formError");
          props.clearTestSelection();
          window.scrollTo(0, 0);
        }
      }).catch(function (resp) {
        if (resp.response.data.type === "internal") {
          setErrorMessage("There was an error with our system while processing your order. <p><strong>Your card has not been charged.</strong></p>Please try again in a few minutes. If you continue to experience issues, please contact help.");
          setShowHelp(true);
        } else {
          setErrorMessage(resp.response.data.message);
          setShowHelp(false);
        }
        props.setDisablePlaceOrderButton(false);
        props.setOrderProcessing(false);
      });
    } else {
      window.scrollTo(0, 0);
      setActiveStep(activeStep + 1);
      props.setDisablePlaceOrderButton(false);
      props.setOrderProcessing(false);
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <React.Fragment>
        {activeStep === steps.length ? (
          <React.Fragment>
            <StripeProvider apiKey={stripeKey}>
              <Elements>
                <StepComponent step={activeStep} />
              </Elements>
            </StripeProvider>
          </React.Fragment>
        ) : (

            (activeStep === steps.length - 1) ? (

              <React.Fragment>

                <Link
                  className={styles.backButton}
                  underline="none"
                  onClick={handleBack}
                >
                  <KeyboardBackspaceIcon />
                  Back
                </Link>
                <div style={{ marginBottom: "50px" }}>
                  <StripeProvider apiKey={stripeKey}>
                    <Elements><InjectedStripeActiveStep /></Elements>
                  </StripeProvider>

                  {errorMessage ? (
                    <div>
                      <ErrorMessage message={errorMessage} showHelp={showHelp} />
                    </div>
                  ) : ("")}


                  <div className={classes.buttons}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleNext}
                      className={classes.button}
                      disabled={props.disablePlaceOrderButton}
                    >
                      {'Place order'}
                    </Button>
                    {props.orderProcessing ? (<CircularProgress size={35} style={{ marginTop: "35px", position: "absolute" }} />) : ""}
                  </div>
                </div>
              </React.Fragment>

            ) : (

                <React.Fragment>
                  <div style={{ marginBottom: "50px" }}>
                    <StripeProvider apiKey={stripeKey}>
                      <Elements>
                        <StepComponent step={activeStep} />
                      </Elements>
                    </StripeProvider>

                    <div className={classes.buttons}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleNext}
                        disabled={props.disableContinueButton}
                        className={classes.button}
                      >
                        {'Continue to Payment'}
                      </Button>
                    </div>
                  </div>
                </React.Fragment>
              ))}
      </React.Fragment>
    </React.Fragment>
  );
}

const StepComponent = (props) => {
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <YourOrder />;
      case 1:
        return <CompleteOrderForm />;
      case 2:
        return <OrderConfirmationForm />;
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    getStepContent(props.step)
  )
};


const InjectedStripeActiveStep = injectStripe(CompleteOrderForm);

const mapStateToProps = state => {
  return {
    selectedProducts: state.selectedProducts,
    selectedBreed: state.selectedBreed,
    stripeToken: state.stripeToken,
    disableContinueButton: state.disableContinueButton,
    disablePlaceOrderButton: state.disablePlaceOrderButton,
    orderForm: state.orderForm,
    ownerForm: state.ownerForm,
    orderHash: state.orderHash,
    orderProcessing: state.orderProcessing
  };
};

export default connect(
  mapStateToProps, { setDisableContineButton, setDisablePlaceOrderButton, setOrderHash, setOrderForm, setOwnerForm, setOrderProcessing, clearTestSelection }
)(Checkout);
