import React, {useEffect} from 'react';
import Money from "../../util/Money";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Link from "@material-ui/core/Link";
import {ProductStyles} from "../../../styles";
import Grid from "@material-ui/core/Grid";
import {getProducts} from "../../util/Api";

const ProductDetail = ({match}) => {
    const styles = ProductStyles();
    const id = parseInt(match.params.id);

    const [data, setData] = React.useState([]);
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const fetchData = async () => {
        const data = await getProducts();
        setData({products: data});
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const goBack = () => {
        window.history.back();
    };

    if (!loading && !error) {
        const product = data.products.find((product) => {
            return product.id === id;
        });
        return (
            <div style={{marginBottom: '50px', marginTop: '25px'}}>
                <Link
                    className={styles.backButton}
                    underline="none"
                    onClick={goBack}
                >
                    <KeyboardBackspaceIcon/>
                    Back
                </Link>
                <Grid container spacing={2} className={styles.productTable}>

                    <Grid item sm={4} xs={12}>
                        Name:
                    </Grid>
                    <Grid item sm={8} xs={12} style={{fontWeight: 600}}>
                        {product.name}
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        Lab ID:
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        {product.labid}
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        Price:
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <Money value={product.price}/>
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        Mutation Found In:
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        {product.foundinbreeds}
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        Background:
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        {product.background}
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        Clinical Description:
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        {product.description}
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        Key signs:
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        {product.keysigns}
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        Mode of Inheritance:
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        {product.inheritancemode}
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        Gene name:
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        {product.genename}
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        Breeder advice:
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        {product.breederadvicespecific}
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        References:
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        {product.sciencereferences}
                    </Grid>
                </Grid>
            </div>
        )
    } else if (error) {
        return <div style={{marginTop: "50px", marginBottom: "50px"}}>product not found</div>
    } else {
        return ("");
    }

};

export default ProductDetail;
