import React from 'react';
import {connect} from 'react-redux';
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import {Link as RouterLink, withRouter} from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import HeaderImage from "./HeaderImage";
import {Typography, useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {compose} from "redux";
import Container from "@material-ui/core/Container";
import Banner from "./Banner";

const useStyles = makeStyles(theme => ({
    toolbar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        '@media print': {
            display: "none"
        }
    },
    toolbarTitle: {
        flex: 1,
    },
    toolbarSecondary: {
        justifyContent: 'space-around',
        height: '100px',
        overflowX: 'auto',
    },
    toolbarSecondaryMobile: {
        justifyContent: 'space-around',
        overflowX: 'auto',
        borderTop: '1px solid #f0f0f0'
    },
    toolbarLink: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        flexShrink: 0,
        fontWeight: 600,
        position: "relative",
        '&::after': {
            transition: 'all .15s ease',
            content: '" "',
            position: 'absolute',
            left: '50%',
            webkitTransform: 'translateX(-50%)',
            transform: 'translateX(-50%)',
            display: 'block',
            height: '1px',
            width: '7px',
            margin: '0px auto',
            borderTop: '3px solid transparent',
        },
        '&:active': {
            transition: 'all .15s ease',
            color: '#04619b',
            '&::after': {
                borderTop: '3px solid #04619b !important',
            }
        },
        '&:hover': {
            textDecoration: 'none !important',
            '&::after': {
                transition: 'all .15s ease',
                borderTop: '3px solid #0c2242',
            }
        }
    },
    activeLink: {
        '&::after': {
            transition: 'all .15s ease',
            content: '" "',
            position: 'absolute',
            display: 'block',
            left: '50%',
            height: '1px',
            width: '100%',
            margin: '0px auto',
            borderTop: '3px solid #0c2242',
        }
    },
    secondHeader: {
        "& img.print-logo": {
            display: "none",
            '@media print': {
                display: "block"
            }
        }
    }
}));

const showHeader = (history) => {

    if (history.location.pathname === '/') {
        return <HeaderImage/>;
    }
    return (
        <Toolbar component="nav" style={{backgroundColor: '#f0f0f0', padding: '20px', justifyContent: 'center', flexDirection: "column"}}>
            <img className="print-logo" src="/logo.svg" alt="Wisdom Panel Logo" style={{marginBottom: "25px"}}/>
            <Typography variant="h6" style={{color:"#04619B", textTransform: "uppercase", fontWeight: 600}}>
                Smarter breeding through genetics.&trade;
            </Typography>
        </Toolbar>
    )
};

const Header = (history) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const path = history.location.pathname;
    return (
        <header>
            <Banner/>
            <Container maxWidth="lg" className={classes.toolbar}>
                <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
                    <Link
                        component={RouterLink}
                        color="primary"
                        noWrap
                        key="Products"
                        variant="body2"
                        to="/products"
                        className={path === '/products' ? [classes.toolbarLink, classes.activeLink].join(' ') : classes.toolbarLink}
                        underline="none"
                        hidden={isMobile}
                    >
                        Products
                    </Link>

                    <Link
                        component={RouterLink}
                        color="inherit"
                        noWrap
                        underline="none"
                        key="Home"
                        variant="body2"
                        to="/"
                    >
                        <img src="/logo.svg" alt="Wisdom Panel Logo"/>
                    </Link>

                    <Link
                        component={RouterLink}
                        color="primary"
                        noWrap
                        key="Help"
                        variant="body2"
                        to="/help"
                        className={path === '/help' ? [classes.toolbarLink, classes.activeLink].join(' ') : classes.toolbarLink}
                        underline="none"
                        hidden={isMobile}
                    >
                        Help
                    </Link>
                </Toolbar>
            </Container>
            <Container maxWidth="lg" hidden={!isMobile} className={classes.toolbar}>
                <Toolbar className={classes.toolbarSecondaryMobile}>
                    <Link
                        component={RouterLink}
                        color="primary"
                        noWrap
                        key="Products"
                        variant="body2"
                        to="/products"
                        className={path === '/products' ? [classes.toolbarLink, classes.activeLink].join(' ') : classes.toolbarLink}
                        underline="none"
                    >
                        Products
                    </Link>
                    <Link
                        component={RouterLink}
                        color="primary"
                        noWrap
                        key="Help"
                        variant="body2"
                        to="/help"
                        className={path === '/help' ? [classes.toolbarLink, classes.activeLink].join(' ') : classes.toolbarLink}
                        underline="none"
                    >
                        Help
                    </Link>
                </Toolbar>
            </Container>
            <div className={classes.secondHeader}>
                {showHeader(history)}
            </div>

        </header>
    );
};


const mapStateToProps = state => {
    return {breeds: state.breeds, selectedBreed: state.selectedBreed};
};

export default compose(withRouter, connect(mapStateToProps, {}))(Header)

