import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {amber, green} from "@material-ui/core/colors";
import ErrorIcon from '@material-ui/icons/Error';
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";

const messageStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        color: "white",
        justifyContent: "space-evenly",
        padding: "15px",
        flexWrap: "wrap",
        alignItems: "center",
        borderRadius: "4px",
        maxWidth: "470px",
        margin: "25px auto",
        fontWeight: 400,
        boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)"
    },
    message: {
        display: "inline-block",
        wordWrap: "break-word",
        width: "70%",
        textAlign: "center"
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.light,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    }
}));

const ErrorMessage = (props) => {
    const classes = messageStyles();
    const message = props.message || "Something has gone wrong processing your request.";
    const showHelpLink = props.showHelp;

    return (
        <div className={[classes.container, classes.error].join(' ')}>

            <ErrorIcon />

            <span className={classes.message}>
                <span dangerouslySetInnerHTML={{__html: message}} />
                {showHelpLink ? (
                    <div style={{marginTop: "15px"}}>
                        <Link to="/help" component={RouterLink} style={{color: "white"}}>
                            Contact Help
                        </Link>
                    </div>
                ) : ""}
            </span>
        </div>

    );
};

export default ErrorMessage;
