import React from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "@material-ui/core/Link";
import userLocale from'../../util/UserLocaleLanguage';

const useStyles = makeStyles(theme => ({
    bannerStyle: {
        borderBottom: `1px solid #f75606`,
        color: "#FFFFFF",
        padding: "10px",
        justifyContent: "space-evenly",
        display: "flex",
        backgroundColor: "#f75606",
        fontWeight: 700,
        fontSize: "14px",
        alignItems: "center",
        textAlign: "center",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column"
        },
    },
    linkStyle: {
        display: "inline-flex",
        color: "#FFFFFF",
        borderBottom: "1px solid #FFFFFF",
        fontWeight: 600,
        "& svg": {
            fontSize: "12px",
            marginTop: "4px"
        }
    }
}));

const Banner = () => {
    const styles = useStyles();

    const linkForLocale = () => {
        if (userLocale() === 'us') {
          return 'https://optimal-selection.com/pages/deprecated-breeder-site';
        } else {
          return 'https://mydogdna.com/pages/deprecated-breeder-site';
        }
      };

    return (
        <React.Fragment>
            <Typography className={styles.bannerStyle} color="primary">
            breeder.wisdompanel.com will be deprecated on July 14, 2023
                <Link className={styles.linkStyle} underline="none" href={linkForLocale()}>
                    Please click here to learn more
                    <ArrowForwardIcon fontSize="small"/>
                </Link>
            </Typography>
        </React.Fragment>
    );
};

export default Banner;
