import React from 'react';
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    footer: {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        [theme.breakpoints.down('xs')]: {
            minHeight: '150px'
        }
    },
}));

const explanations = {
    "Autosomal Recessive": {
        "description": "{{productName}} is inherited in an autosomal recessive manner meaning that two copies of the mutation are needed for clinical signs of the condition to develop.",
        "clear": "The results of {{petName}}’s {{productName}} test indicate that they are {{testResult}}. This means that they should not develop clinical signs from this mutation. <br />When making breeding choices, {{petName}} can be bred to any appropriate mate as they will not produce any puppies at risk of developing {{productName}}.",
        "carrier": "The results of {{petName}}’s {{productName}} test indicate that they are {{testResult}}. This means that they should not develop clinical signs from this mutation. <br />When making breeding choices, {{petName}}} should only be bred to a genetically-confirmed Clear mate. A Clear x Carrier mating will produce no puppies at risk of developing {{productName}} but approximately 50% of puppies will carry the mutation",
        "at risk": "The results of {{petName}}’s {{productName}} test indicate that they are {{testResult}}. This means that they will likely develop clinical signs from this mutation. <br />When making breeding choices, {{petName}} should only be bred to a genetically-confirmed Clear mate. A Clear x At Risk mating will produce no puppies at risk of developing {{productName}} but all of the puppies will carry the mutation."
    },
    "Autosomal Dominant with incomplete penetrance": {
        "description": "{{productName}} is inherited in an autosomal dominant manner meaning that only one copy of the mutation is needed for clinical signs of the condition to develop. However, not all dogs with one copy of the mutation develop clinical signs because the condition has incomplete penetrance. ",
        "clear": "The results of {{petName}}’s {{productName}} test indicate that they are {{testResult}}. This means that they should not develop clinical signs from this mutation. <br />When making breeding choices, {{petName}} should only be bred to genetically-confirmed Clear mates as a first choice.",
        "at risk (1 copy)": "The results of {{petName}}’s {{productName}} test indicate that they are {{testResult}}. This means that they will likely develop clinical signs from this mutation. <br />When making breeding choices, {{petName}} should only be bred to a genetically-confirmed Clear mate. Puppies from a Clear x At Risk (1 copy) mating have an approximately 50% chance of carrying the mutation and these puppies may go on to develop clinical signs. If possible, breeding a genetically At Risk dog should be avoided if possible.",
        "at risk (2 copy)": "The results of {{petName}}’s {{productName}} test indicate that they are {{testResult}}. This means that they will likely develop clinical signs from this mutation. <br />When making breeding choices, it would be best to not breed {{petName}}. "
    },
    "Polygenic": {
        "description": "{{productName}} assesses your dog’s risk of developing the condition. Because this is not a direct mutation test, there are known cases where a dog’s risk profile does not match its clinical state.",
        "low risk": "The results of {{petName}}’s {{productName}} test indicate that they are {{testResult}} of developing DMS. This means that their risk of developing clinical signs is 0-5%. <br />When making breeding choices, mate selection should take into account the results of each dog’s A and B loci. Breeding pairs should be selected such that the resulting puppies do not have high risk genotypes. The DMS Genotype Calculator, hosted by the American Shetland Sheepdog Association, may help you assess breeding mates for {{petName}}.",
        "moderate risk": "The results of {{petName}}’s {{productName}} test indicate that they are at {{testResult}} of developing DMS. This means that their risk of developing clinical signs is 33-50%. <br />When making breeding choices, mate selection should take into account the results of each dog’s A and B loci. Breeding pairs should be selected such that the resulting puppies do not have high risk genotypes. The DMS Genotype Calculator, hosted by the American Shetland Sheepdog Association, may help you assess breeding mates for {{petName}}.",
        "high risk": "The results of {{petName}}’s {{productName}} test indicate that they are at {{testResult}} of developing DMS. This means that their risk of developing clinical signs is 90-100%. <br />When making breeding choices, mate selection should take into account the results of each dog’s A and B loci. Breeding pairs should be selected such that the resulting puppies do not have high risk genotypes. The DMS Genotype Calculator, hosted by the American Shetland Sheepdog Association, may help you assess breeding mates for {{petName}}.",
        "undetermined risk": "The results of {{petName}}’s {{productName}} test indicate that they are at {{testResult}} of developing DMS. There is insufficient data to confidently determine the risk associated with this genotype because the DLA DBB1 allele your dog carries is rare. <br /> When making breeding choices, mate selection should take into account the results of each dog’s A and B loci. Breeding pairs should be selected such that the resulting puppies do not have high risk genotypes. The DMS Genotype Calculator, hosted by the American Shetland Sheepdog Association, may help you assess breeding mates for {{petName}}.",
    },
    "Autosomal Recessive with heterozygous effect": {
        "description": "{{productName}} is inherited in an autosomal recessive manner meaning that two copies of the mutation are needed for the severe form of the condition to develop. However, dogs with one copy of the mutation can develop certain clinical signs associated with the condition. ",
        "clear": "The results of {{petName}}’s {{productName}} test indicate that they are {{testResult}}. This means that they should not develop clinical signs from this mutation. <br />When making breeding choices, {{petName}} should only be bred to genetically-confirmed Clear mates as a first choice, though a genetically-confirmed Carrier could still be used. Puppies from a Clear x Carrier mating have an approximately 50% chance of carrying the mutation and these puppies may go on to develop clinical signs.",
        "carrier": "The results of {{petName}}’s {{productName}} test indicate that they are {{testResult}}. This means that they may exhibit certain clinical signs due to this mutation. <br />When making breeding choices, {{petName}} should only be bred to a genetically-confirmed Clear mate. Puppies from a Clear x Carrier mating have an approximately 50% chance of carrying the mutation and these puppies may go on to develop clinical signs.",
        "at risk": "The results of {{petName}}’s {{productName}} test indicate that they are {{testResult}}. This means that they will likely develop clinical signs from this mutation. <br />When making breeding choices, {{petName}} should only be bred to a genetically-confirmed Clear mate. A Clear x At Risk mating will produce litters where all the puppies carry the mutation. These puppies may go on to develop certain clinical signs associated with {{productName}} because the condition has a heterozygous effect. If possible, breeding a genetically At Risk dog should be avoided if possible."
    },
    "Linked Marker for Autosomal Recessive with incomplete penetrance": {
        "description": "{{productName}} appears to be inherited in an autosomal recessive manner meaning that two copies of the mutation are needed for clinical signs of the condition to develop. The {{productName}} test is a linked marker test that assesses a dog’s risk of developing clinical signs associated with the disease. Because this is not a direct mutation test, there are known cases where a dog’s risk profile does not match its clinical state. ",
        "homozygous wild type": "The results of {{petName}}’s {{productName}} test indicate that they are {{testResult}}. This means that they should not develop clinical signs associated with the condition. <br />When making breeding choices, {{petName}} can be bred to any appropriate mate as they will not produce any puppies at risk of developing {{productName}}.",
        "heterozygous": "The results of {{petName}}’s {{productName}} test indicate that they are {{testResult}}. This means that they should not develop clinical signs associated with the condition but they do carry one copy of the risk variant. <br />When making breeding choices, {{petName}} should only be bred to a genetically-confirmed Homozygous Wild Type mate. A Homozygous Wild Type x Heterozygous mating will produce no puppies at risk of developing {{productName}} but approximately 50% of puppies will carry risk variant.",
        "homozygous risk variant": "The results of {{petName}}’s {{productName}} test indicate that they are {{testResult}}. This means that they will likely develop clinical signs associated with the condition because they have two copies of the risk variant. <br />When making breeding choices, {{petName}} should only be bred to a genetically-confirmed Clear mate. A Clear x At Risk mating will produce no puppies at risk of developing {{productName}} but all of the puppies will carry the risk variant.",
    }
};

const replace = (key, value, content) => {
    if (!content) {
        return;
    }
    const regexp = new RegExp('{{' + key + '}}', 'gm');
    content = content.replace(regexp, value);
    return content;
};

const ResultExplanation = (props) => {
    if (!props.product || !props.result || !props.petName) {
        return ("");
    }
    const result = explanations[props.product.inheritancemode];
    let description = result.description;
    let message = result[props.result.toLowerCase()];
    if (!message && props.dmsRisk) {
        message = result[props.dmsRisk.toLowerCase()];
    }
    description = replace("productName", props.product.name, description);
    message = replace("productName", props.product.name, message);
    message = replace("petName", props.petName, message);

    if (props.dmsRisk) {
        message = replace("testResult", props.dmsRisk.toLowerCase(), message);
    } else {
        message = replace("testResult", props.result.toLowerCase(), message);
    }


    return (
        <div style={{marginTop: "25px", marginBottom: "25px"}}>
            <Typography variant={"body1"} dangerouslySetInnerHTML={{__html: description}} gutterBottom/>
            <Typography variant={"body1"} dangerouslySetInnerHTML={{__html: message}}/>
            <Typography variant={"subtitle1"} style={{paddingTop: "15px"}}>
                Please note: It is possible that disease signs similar to the ones associated with this risk variant
                could develop due to a different genetic or clinical cause.
            </Typography>
        </div>
    )
};


export default ResultExplanation;
