import {makeStyles} from '@material-ui/core/styles';

const ResultListStyles = makeStyles(theme => ({
    container: {
        marginBottom: '50px',
        marginTop: '25px',
        maxWidth: '768px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    listItem: {
        borderBottom: "2px solid #f0f0f0",
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    chip: {
        fontWeight: 700,
        color: theme.palette.primary.main
    }
}));

export default ResultListStyles;
