import { combineReducers } from 'redux';
import testsReducer from './testsReducer';
import breedsReducer from './breedsReducer';
import showAllProductsReducer from './showAllProductsReducer';
import * as constants from '../constants';

const stripeToken = (stripeToken = null, action) => {
  if (action.type === constants.SET_STRIPE_TOKEN) {
    return action.payload;
  }
  return stripeToken;
};

const selectedBreed = (selectedBreed = null, action) => {
    if (action.type === constants.SELECT_BREED) {
      return action.payload;
    }
    return selectedBreed;
};

const orderForm = (orderForm = {}, action) => {
    if (action.type === constants.SET_ORDER_FORM) {
        return action.payload;
    }
    return orderForm;
};

const ownerForm = (ownerForm = {}, action) => {
    if (action.type === constants.SET_OWNER_FORM) {
        return action.payload;
    }
    return ownerForm;
};

const disableContinueButton = (disableContinueButton = null, action) => {
  if (action.type === constants.SET_DISABLE_CONTINUE_BUTTON) {
    return action.payload;
  }
  return disableContinueButton;
};

const disablePlaceOrderButton = (disablePlaceOrderButton = null, action) => {
  if (action.type === constants.SET_DISABLE_PLACE_ORDER_BUTTON) {
    return action.payload;
  }
  return disablePlaceOrderButton;
};

const orderProcessing = (orderProcessing = false, action) => {
    if (action.type === constants.SET_ORDER_PROCESSING) {
        return action.payload;
    }
    return orderProcessing;
};

const orderhash = (orderhash = null, action) => {
    if (action.type === constants.SET_ORDER_HASH) {
        return action.payload;
    }
    return orderhash;
};

const permission = (permission = null, action) => {
  if (action.type === constants.SET_PERMISSION) {
      return action.payload;
  }
  return permission;
};

const authToken = (authToken = null, action) => {
  if (action.type === constants.SET_AUTH_TOKEN) {
      return action.payload;
  }
  return authToken;
};

export default combineReducers({
    selectedProducts: testsReducer,
    breeds: breedsReducer,
    stripeToken: stripeToken,
    selectedBreed: selectedBreed,
    showAllProducts: showAllProductsReducer,
    disableContinueButton: disableContinueButton,
    disablePlaceOrderButton: disablePlaceOrderButton,
    orderForm: orderForm,
    ownerForm: ownerForm,
    orderhash: orderhash,
    orderProcessing: orderProcessing,
    permission: permission,
    authToken: authToken
});


