import React from 'react';
import Typography from '@material-ui/core/Typography';
import {Link} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";

const ShippingInstructions = (props) => {
    return (
        <React.Fragment>
            <Typography variant="h4" gutterBottom color="primary" >
                Shipping Instructions
            </Typography>
            <p>Now it’s time to get {props.dogName}'s sample and get it ready to ship following the shipping
                instructions below. Please be sure to include a copy of this Packing Slip with your
                shipment.</p>

            <ol>
                <li>Print the Packing Slip that was emailed to you and note the Order ID.</li>
                <li>Obtain the sample from your dog. Instructions on Swabbing Your Dog are
                    here.
                </li>
                <li>Write your dog’s call name and the Order ID number on the sample envelope or blood tube.
                    When submitting samples on multiple dogs, ensure swab samples from each dog are
                    separated to prevent cross-contamination.
                </li>
                <li>If submitting blood samples, place each tube in a separate plastic, zippered bag along
                    with an absorbent material such as a gauze strip.
                </li>
                <li>Place swabs or blood tubes in a secondary mailing envelope. Include the Packing Slip in
                    the mailer.
                </li>
                <li>Do NOT use dry ice, regular ice, or cold packs when shipping your samples. Moisture can
                    promote bacterial growth.
                </li>
                <li>Mail your samples back to our lab using one of the following addresses:</li>
            </ol>

            <Typography color="primary" variant="h6" style={{marginTop: "50px"}}>
                For shipments sent via USPS or regular post
            </Typography>

            <p>Wisdom Health</p>

            <p>PO Box 80839</p>

            <p>Lincoln NE 68501</p>

            <Typography color="primary" variant="h6">
                For overnight or 2 day shipments via FedEx, UPS, DHL, etc.
            </Typography>

            <p>Wisdom Health</p>

            <p>c/o Samples</p>

            <p>4131 N 48th St,</p>

            <p>Lincoln, NE 68504</p>

            <Typography variant="body2">
                If you are shipping from outside of the United States, <Link component={RouterLink} to="/help?international-shipping">please follow our International Shipping
                Instructions.</Link>
            </Typography>

        </React.Fragment>
    );

}

export default ShippingInstructions;
