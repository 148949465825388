import React from "react";
import FaqList from "./FaqList";
import {Typography, useTheme} from "@material-ui/core";
import {FaqStyles} from "../../../styles";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

const HelpPage = () => {
    const faqStyles = FaqStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <React.Fragment>
            <div style={{marginTop: '25px'}}>
                <div className={faqStyles.contactContainer} style={isMobile ? {margin: '10px', marginBottom: '25px', float: 'none'}: {}}>
                    <Typography variant="h6" gutterBottom color="primary"
                                style={{fontWeight: 600, textTransform: 'uppercase'}}>Need Help?</Typography>
                    <Typography variant="subtitle2" color="primary" gutterBottom>
                        <a href="mailto:info@wisdomhealth.com" target="_blank">info@wisdomhealth.com</a>
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom color="primary">
                        855-619-1884
                    </Typography>
                </div>
                <FaqList/>
            </div>
        </React.Fragment>
    );
};

export default HelpPage;
