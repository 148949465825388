import React, {useEffect, useState} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import Template from './pages/misc/Template';
import AboutPage from './pages/misc/About';
import HelpPage from './pages/misc/Help';
import Login from "./pages/admin/Login";
import Products from './pages/products/Products';
import ProductDetail from './pages/products/ProductDetail';
import ResultsList from './pages/result/ResultsList';
import Checkout from './pages/order/Checkout';
import BreedSelector from "./pages/breed/BreedSelector";
import {useLocation} from "react-router-dom";
import Result from "./pages/result/Result";
import {AuthContext} from "../context/auth";
import OrderDetail from './pages/admin/OrderDetail';
import OrderStatus from './pages/order/OrderStatus';
import OrderSearch from './pages/admin/OrderSearch';
import PrivateRoute from './PrivateRoute';
import PackageSlip from "./pages/misc/PackageSlip";
import SpecialMessage from "./pages/misc/SpecialMessage";
import CookiePolicy from "./pages/misc/CookiePolicy";

function ScrollToTop() {
    const {pathname} = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return null;
}

const App = (props) => {
    const [authTokens, setAuthTokens] = useState();

    const setTokens = (data) => {
        localStorage.setItem("tokens", JSON.stringify(data));
        setAuthTokens(data);
    }

    return (
        <AuthContext.Provider value={{authTokens, setAuthTokens: setTokens}}>
            <BrowserRouter>
                <ScrollToTop/>
                <Template>
                    <Switch>
                        <Route path="/" exact component={BreedSelector}/>
                        <Route path="/products" exact component={Products}/>
                        <Route path="/product/:id" component={ProductDetail}/>
                        <Route exact path="/results/:orderId" component={ResultsList}/>
                        <Route exact path="/order/:orderId" component={OrderStatus}/>
                        <Route exact path="/results/:orderId/:testId" component={Result}/>
                        <Route path="/about" exact component={AboutPage}/>
                        <Route path="/cookie-policy" exact component={CookiePolicy}/>
                        <Route exact path="/package/:orderId" component={PackageSlip}/>
                        <Route path="/help" exact component={HelpPage}/>
                        <Route path="/checkout" exact component={Checkout}/>
                        <Route path="/login" component={Login}/>
                        <Route path="/special-message" exact component={SpecialMessage}/>

                        <PrivateRoute exact path="/admin/order/:orderId" component={OrderDetail}/>
                        <PrivateRoute exact path="/admin" component={OrderSearch}/>

                        <Redirect to="/"/>
                    </Switch>
                </Template>
            </BrowserRouter>
        </AuthContext.Provider>
    );
};

export default App;
