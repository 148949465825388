const mapping = [
    {
        "G/G": "aa",
        "G/A": "Aa",
        "A/A": "AA"
    },
    {
        "ACTCCACAAA/ACTCCACAAA": "bb",
        "ACTCCACAAA/GACT": "Bb",
        "GACT/GACT": "BB"
    },
    {
        "002:01/002:01": "CC",
        "002:01/009:01": "Cc",
        "002:01/023:01": "Cc",
        "002:01/015:01": "Cc",
        "002:01/037:01": "Cc",
        "002:01/undetermined": "Cc",
        "002:01/46:01": "Cc",
        "009:01/023:01": "cc",
        "009:01/015:01": "cc",
        "009:01/037:01": "cc",
        "009:01/undetermined": "cc",
        "009:01/46:01": "cc",
        "023:01/015:01": "cc",
        "023:01/037:01": "cc",
        "023:01/undetermined": "cc",
        "023:01/46:01": "cc",
        "015:01/037:01": "cc",
        "015:01/undetermined": "cc",
        "015:01/46:01": "cc",
        "037:01/undetermined": "cc",
        "037:01/46:01": "cc",
        "undetermined/undetermined": "cc",
        "undetermined/46:01": "cc"
    }
];

const dmsResult = {
    "aabbcc": "Undetermined Risk",
    "aabbCc": "Low Risk",
    "aabbCC": "Low Risk",
    "Aabbcc": "Undetermined Risk",
    "AabbCc": "Low Risk",
    "AabbCC": "Low Risk",
    "aaBbcc": "Low Risk",
    "aaBbCc": "Low Risk",
    "aaBbCC": "Low Risk",
    "AaBbcc": "Undetermined Risk",
    "AaBbCc": "Low Risk",
    "AaBbCC": "Low Risk",
    "AAbbcc": "Undetermined Risk",
    "AAbbCc": "Moderate Risk",
    "AAbbCC": "Moderate Risk",
    "aaBBcc": "Undetermined Risk",
    "aaBBCc": "Low Risk",
    "aaBBCC": "Moderate Risk",
    "AaBBcc": "Undetermined Risk",
    "AaBBCc": "Moderate Risk",
    "AaBBCC": "High Risk",
    "AABbcc": "Undetermined Risk",
    "AABbCc": "Moderate Risk",
    "AABbCC": "High Risk",
    "AABBcc": "Undetermined Risk",
    "AABBCc": "High Risk",
    "AABBCC": "High Risk",
};


const getDmsResult = (testResult) => {
    const splitResult = testResult.split(";");
    const resultString = splitResult.map((res, index) => {
        return mapping[index][res];
    }).join("");

    const displayResult = `${resultString.substring(0, 4)} ${splitResult[2]}`;
    return {risk: dmsResult[resultString], displayResult}
};


export default getDmsResult
