import React, {useEffect} from 'react';
import api, {getProducts} from "../../util/Api";
import {Typography} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import List from "@material-ui/core/List";
import ShippingInstructions from "./ShippingInstructions";
import Loading from "./Loading";
import ErrorMessage from "../../util/ErrorMessage";
import Grid from "@material-ui/core/Grid";
import {ProductStyles} from "../../../styles";

const PackageSlip = (props) => {
    const styles = ProductStyles();
    const orderId = props.match.params.orderId;
    const [order, setOrder] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [products, setProducts] = React.useState([]);
    const [barCode, setBarcode] = React.useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                let token = props.location.search.split("=")[1];
                const order = await api.get(`/api/orders/${orderId}?token=${token}`);
                const productsResults = await getProducts();
                setProducts(productsResults.filter(product => {
                    return order.data[0].products.indexOf(product.id) > -1
                }));
                setOrder(order.data[0]);

                const barcode = await api.get(`/api/barcode/${order.data[0].hash}`);
                setBarcode(barcode.data);
                setLoading(false);
            } catch (e) {
                setLoading(false);
                setError(e.message);
            }
        }

        fetchData();
    }, [orderId]);

    if (loading) {
        return (
            <Loading message="Loading your package slip"/>
        )
    } else if (error) {
        return (
            <ErrorMessage message={error}/>
        )
    }
    return (
        <Container maxWidth="sm" style={{marginTop: "50px", marginBottom: "50px"}}>
            <div style={{textAlign: "center", marginBottom: "50px"}}>
                <Typography variant="h6" color="primary" style={{marginBottom: "15px", fontWeight: 700}}>
                    PACKING SLIP
                </Typography>

                <Grid container spacing={2} className={styles.productTable}>
                    <Grid item sm={6} xs={12}>
                        Call Name:
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        {order.dogname}
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        Owner Name:
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        {order.ownerfirstname} {order.ownerlastname}
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        Gender:
                    </Grid>
                    <Grid item sm={6} xs={12} style={{textTransform: "capitalize"}}>
                        {order.sex}
                    </Grid>
                </Grid>

            </div>
            <div style={{marginBottom: "50px"}} className={styles.packageSlipBreak}>
                <Typography variant="body2" style={{marginBottom: "10px", fontWeight: 600}}>Tests
                    Ordered:</Typography>
                <List disablePadding style={{borderBottom: "1px solid black", marginBottom: "50px"}}>
                    {products.map((test) => (
                        <ListItem key={test.name} disableGutters style={{borderTop: "1px solid #f0f0f0"}}>
                            <ListItemText primary={test.name} secondary={test.desc} style={{marginRight: '135px'}}/>
                            <ListItemSecondaryAction>
                                <Typography variant="body2" style={{color: "rgba(0,0,0,0.5)"}}>
                                    {test.labid}
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>

                {barCode ? (
                    <div style={{textAlign: "center"}}>
                        <img src={barCode} alt="Barcode image for order"/>
                    </div>
                ) : ("")}

            </div>

            <ShippingInstructions dogName={order.dogname}/>
        </Container>
    )
};


export default PackageSlip;
