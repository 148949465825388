import React, {useEffect} from "react";

import {Typography} from "@material-ui/core";
import {FaqStyles} from "../../../styles";
import {getFaqs} from "../../util/Api";

const FaqList = props => {
    const faqStyles = FaqStyles();
    const [data, setData] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const ref = React.createRef();

    useEffect((props) => {
        async function fetchData() {
            try {
                const faqs = await getFaqs();
                setData(faqs);
                setLoading(false);
                if (window.location.href.indexOf("international-shipping") > -1 && ref && ref.current) {
                    ref.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                }
            } catch (e) {
                setError(true);
            }
        }
        fetchData();
    }, [loading]);

    if (error) {
        return (<div>
            <Typography>
                Could not load Frequently asked questions. Please try again in a minute.
            </Typography>
        </div>)
    } else if (loading) {
        return <div></div>;
    } else {
        return (
            <React.Fragment>
                <Typography variant="h4" style={{marginTop: '10px', marginBottom: '10px'}} color="primary">
                    Frequently Asked Questions
                </Typography>
                {data.map(faq => {
                    return (
                        <div key={faq.id} className={faqStyles.questionContainer} ref={faq.id === 33 ? ref : null}>
                            <Typography variant="h6" component="h3" className={faqStyles.question} color="primary"
                                        gutterBottom>
                                {faq.question}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom={true} dangerouslySetInnerHTML={{__html: faq.answer}} />
                        </div>
                    );
                })}
            </React.Fragment>
        );
    }
};

export default FaqList;
