import React, { useEffect } from 'react';
import Portal from "@material-ui/core/Portal";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Link from "@material-ui/core/Link";
import { ProductStyles, ResultStyles } from "../../../styles";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import api, { getBreeds, getProducts } from "../../util/Api";
import { compose } from "redux";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ResultExplanation from "./ResultExplanation";
import getDmsResult from "../../util/CalculateTestResult";


const Result = (props) => {
    const productStyles = ProductStyles();
    const resultStyles = ResultStyles();
    const orderId = props.match.params.orderId;

    const search = props.location.search;
    let authToken;
    if (search) {
        authToken = search.split("=")[1];
    }

    const [testPerformed, setTestPerformed] = React.useState(false);
    const container = React.useRef(null);
    const [dogName, setDogName] = React.useState(false);
    const [breed, setBreed] = React.useState(false);
    const [birthDate, setBirthDate] = React.useState(false);
    const [sex, setSex] = React.useState(false);
    const [testResult, setTestResult] = React.useState(false);
    const [dmsRisk, setDmsRisk] = React.useState(false);
    const [testCompleted, setTestCompleted] = React.useState(false);
    const [sampleId, setSampleId] = React.useState(false);
    const [ownerName, setOwnerName] = React.useState(false);
    const [registrationId, setRegistrationId] = React.useState(false);
    const [microchipNumber, setMicrochipNumber] = React.useState(false);
    const [reportDate, setReportDate] = React.useState(false);

    useEffect(() => {
        async function fetchData() {
            let order, result;
            if (props.authToken) {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.authToken
                };
                order = await api.get(`/api/orders/${orderId}`, {
                    headers: headers
                });
                result = await api.get(`/api/results/${orderId}`, {
                    headers: headers
                });
            } else {
                order = await api.get(`/api/orders/${orderId}?token=${authToken}`);
                result = await api.get(`/api/results/${orderId}?token=${authToken}`);
            }

            const products = await getProducts();
            const breeds = await getBreeds();

            const currentProduct = products.find(product => {
                return product.id === parseInt(props.match.params.testId)
            });

            const currentBreed = breeds.find(breed => {
                return breed.id === order.data[0].breed
            });

            setTestPerformed(currentProduct);
            setDogName(order.data[0].dogname);

            setBirthDate(new Date(order.data[0].birthdate).toLocaleDateString('en-US'));
            setSex(order.data[0].sex);
            setOwnerName(`${order.data[0].ownerfirstname} ${order.data[0].ownerlastname}`);
            setRegistrationId(order.data[0].registrationid);
            setMicrochipNumber(order.data[0].microchipnumber);
            setSampleId(order.data[0].hash);

            let testResult = result.data.find(obj => obj.testPerformed === currentProduct.id);
            if (testResult) {

                setTestCompleted(testResult.testCompleted);
                setReportDate(testResult.reportDate);
                if (currentProduct.id === 26) {
                    const dmsResult = getDmsResult(testResult.result);
                    setTestResult(dmsResult.displayResult);
                    setDmsRisk(dmsResult.risk);
                } else {
                    setTestResult(testResult.result);
                }
            }

            if (currentBreed.breed === "Other") {
                setBreed(order.data[0].custombreedname);
            } else {
                setBreed(currentBreed.breed);
            }
        }

        fetchData();
    }, [orderId]);

    const goBack = () => {
        window.history.back();
    };

    return (
        <Portal container={container.current}>
            <div className={resultStyles.container}>
                <Link
                    className={productStyles.backButton}
                    underline="none"
                    onClick={goBack}
                >
                    <KeyboardBackspaceIcon color="primary" />
                    Tests
                </Link>
                <div className={resultStyles.certificate}>
                    <div className={resultStyles.insetBorder}></div>
                    <div className={resultStyles.insetBorder}></div>
                    <div className={resultStyles.insetBorder}></div>
                    <div className={resultStyles.insetBorder}></div>

                    <div className={resultStyles.headerLogo}>
                        <img src="/logo.svg" alt="Wisdom Panel Logo" />
                    </div>
                    <Typography variant="h6" style={{ textAlign: "center", color: "gray", fontWeight: 600 }}>
                        TEST RESULT
                    </Typography>
                    <Grid container direction="row" justify="center" alignItems="baseline">
                        <table className={resultStyles.resultTable}>
                            <tbody>
                                <tr>
                                    <td>Test Performed:</td>
                                    <td>{testPerformed.name}</td>
                                </tr>
                                <tr>
                                    <td>Result:</td>
                                    <td>{testResult}</td>
                                </tr>
                                <tr style={{ height: "25px" }}></tr>
                                <tr>
                                    <td>Call Name:</td>
                                    <td>{dogName}</td>
                                </tr>
                                <tr>
                                    <td>Breed:</td>
                                    <td>{breed}</td>
                                </tr>
                                <tr>
                                    <td>Birthdate:</td>
                                    <td>{birthDate}</td>
                                </tr>
                                <tr>
                                    <td>Sex:</td>
                                    <td style={{ textTransform: "capitalize" }}>{sex}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className={resultStyles.resultTable}>
                            <tbody>
                                <tr>
                                    <td>Test Completed:</td>
                                    <td>{testCompleted}</td>
                                </tr>
                                <tr>
                                    <td>Sample Id:</td>
                                    <td>{sampleId}</td>
                                </tr>
                                <tr style={{ height: "25px" }}></tr>
                                <tr>
                                    <td>Owner:</td>
                                    <td>{ownerName}</td>
                                </tr>
                                <tr>
                                    <td>Registration ID:</td>
                                    <td>{registrationId}</td>
                                </tr>
                                <tr>
                                    <td>Microchip #:</td>
                                    <td>{microchipNumber}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={resultStyles.certificateFooter}>
                            <div className={resultStyles.signedElement}>
                                <Typography className={resultStyles.aboveLineTypography}>
                                    <img
                                        src="/signature-becca.png"
                                        alt="becca-signature"
                                        style={{ width: "250px", marginBottom: "-25px" }} />
                                </Typography>
                                <Typography className={resultStyles.linedTypography} style={{ paddingRight: "50px" }}>
                                    Rebecca Chodroff Foran, PHD
                                </Typography>
                            </div>
                            <div className={resultStyles.signedElement}>
                                <Typography className={resultStyles.aboveLineTypography}>
                                    {reportDate}
                                </Typography>
                                <Typography className={resultStyles.linedTypography}>
                                    Report Date
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                </div>
                <div className={resultStyles.footer}>
                    <Typography variant="body2">
                        <ResultExplanation product={testPerformed} result={testResult} petName={dogName} dmsRisk={dmsRisk} />
                    </Typography>

                    <Typography className={resultStyles.hideOnPrint} gutterBottom>For your records, this page is printer-friendly</Typography>
                    <div className={resultStyles.footerContact}>
                        <Typography style={{ fontWeight: 600 }}>
                            Wisdom Health, a division of Mars Petcare US
                        </Typography>
                        <Typography>
                            855-619-1884
                        </Typography>
                        <hr />
                        <Typography>
                            18101 SE 6th Way, Vancouver, WA 98683
                        </Typography>
                        <Typography>
                            info@wisdomhealth.com
                        </Typography>
                    </div>
                </div>
            </div>
        </Portal>
    )
};

const mapStateToProps = state => {
    return { authToken: state.authToken };
};

export default compose(withRouter, connect(mapStateToProps,
    {}
))(Result)
