import React from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import "react-datepicker/dist/react-datepicker.css";
import ResultFooter from "../result/ResultFooter";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    paragraph: {
        marginBottom: theme.spacing(3),
    },
}));

const OrderConfirmationForm = (props) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <div style={{marginTop: "35px", marginBottom: "50px"}}>
                <Typography variant="h5" gutterBottom style={{fontWeight: 600, marginBottom: "35px"}} color="primary">
                    Your Order is Confirmed
                </Typography>
                <Grid container>
                    <Grid item xs={12} sm={7}>
                        <Typography variant="subtitle1" gutterBottom className={classes.paragraph}>
                            Thanks for your order! You'll receive an email confirmation at {props.email}.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom className={classes.paragraph}>
                            Your confirmation email will also include instructions on submitting your sample and what to
                            expect.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom className={classes.paragraph}>
                            Note: It's important to print packing slip from email
                        </Typography>

                        <Typography variant="h5" color="primary">
                            Order ID: <span style={{fontWeight: 600}}>{props.orderhash}</span>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <img style={{height: 'auto', width: "100%", display: 'flex', margin: 'auto'}}
                             src="/australian-shepherd.jpg" alt="Australian Shepherd"/>
                    </Grid>
                </Grid>

                <div style={{marginTop: "25px"}}>
                    <ResultFooter/>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {selectedBreed: state.selectedBreed, orderhash: state.orderhash, email: state.orderForm.email};
};

export default connect(
    mapStateToProps,
    {}
)(OrderConfirmationForm);
