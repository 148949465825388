import makeStyles from "@material-ui/core/styles/makeStyles";

const ImageStyles = makeStyles({
    imageContainer: {
        width: '100%',
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden'
    },
    image: {
        width: "auto",
        maxWidth: "100%"
    },
    banner: {
        padding: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        margin: 0
    },
    slogan: {
        color: '#96DFF4',
        position: 'absolute',
        top: '10%',
        right: 0,
        width: '50%',
        height: '100%',
        textTransform: 'uppercase',
        textAlign: 'left'
    },
    sloganLine1: {
        fontWeight: 700,
    },
    sloganLine2: {
        fontWeight: 700,
        paddingLeft: '25px'
    },
    sloganLine3: {
        fontWeight: 700,
        paddingLeft: '50px',
        lineHeight: '1.2em'
    },
});

export default ImageStyles;
