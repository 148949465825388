import axios from 'axios';
import runtimeEnv from "@mars/heroku-js-runtime-env";

const env = runtimeEnv();
const apiUrl = env.REACT_APP_API_URL || "http://localhost:3001";

let cachedResponses = {};

const api = axios.create({
    baseURL: apiUrl
});
export default api;

export async function getFaqs() {
    if (cachedResponses["faqs"]) {
        return cachedResponses["faqs"];
    }
    const result = await api.get("/api/faqs");
    cachedResponses["faqs"] = result.data;
    return result.data
}

export async function getBreeds() {
    if (cachedResponses["breeds"]) {
        return cachedResponses["breeds"];
    }
    const result = await api.get("/api/breeds");
    result.data.sort((a, b) => (a.breed > b.breed) ? 1 : -1);

    cachedResponses["breeds"] = result.data;
    return result.data
}

export async function getProducts() {
    if (cachedResponses["products"]) {
        return cachedResponses["products"];
    }
    const result = await api.get("/api/products");
    cachedResponses["products"] = result.data;
    return result.data
}
