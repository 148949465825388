import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {selectBreed, clearTestSelection, toggleShowAllProducts} from '../../../actions';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {getBreeds} from "../../util/Api";
import ErrorMessage from "../../util/ErrorMessage";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: "auto",
        marginTop: '15px',
        minWidth: 120,
        '& .MuiSelect-outlined': {
            paddingRight: "30px"
        }
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const BreedDropdown = (props) => {
    const classes = useStyles();
    const [breed, setBreed] = React.useState(1);
    const [data, setData] = React.useState([]);
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const fetchData = async() =>{
        try {
            const data = await getBreeds();
            setData(data);
            setLoading(false);
        } catch (e) {
            setError(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!loading && !error && props.selectedBreed === null) {
            props.selectBreed({breed: 'Other', id: 'select-a-breed'});
            setBreed('select-a-breed');
            props.toggleShowAllProducts();
        } else if (!loading && !error && props.selectedBreed) {
            setBreed(props.selectedBreed.id);
        } else {
            fetchData();
        }
    }, [data, error, loading, props]);

    const handleChange = (name) => (event) => {
        let breed = data.find((breed) => breed.id === parseInt(event.target.value));
        setBreed(breed.id);
        props.selectBreed(breed);

        if(props.clearOnChange !== false) {
            props.clearTestSelection();
        }
        if(breed.breed === "Other" && !props.showAllProducts) {
            props.toggleShowAllProducts();
        } else if(props.showAllProducts && breed.breed !== "Other") {
            props.toggleShowAllProducts();
        }
    };

    if (loading && !error) {
        return <div className={classes.root}>loading...</div>
    } else if(error){
        return (<ErrorMessage message="Could not load available breeds. Please try again in a minute." />)
    } else {
        return (
            <form className={classes.root} autoComplete="off" >
                <FormControl className={classes.formControl} style={props.style} variant="outlined">
                    <Select
                        value={breed}
                        onChange={handleChange('breed')}
                        inputProps={{
                            name: 'breed-dropdown',
                            id: 'breed-dropdown',
                        }}
                    >
                        <MenuItem disabled value="select-a-breed" key="select-a-breed">Select Breed</MenuItem>
                        {
                            data.map(breed => {
                                return (
                                    <MenuItem value={breed.id} key={`breed-` + breed.id}>{breed.breed}</MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>
            </form>
        );
    }

};


  const mapStateToProps = state => {
    return { selectedBreed: state.selectedBreed, showAllProducts: state.showAllProducts };
  };

  export default connect(
    mapStateToProps,
    { selectBreed, clearTestSelection, toggleShowAllProducts }
  )(BreedDropdown);
