import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {Link as RouterLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    footer: {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        [theme.breakpoints.down('xs')]: {
            minHeight: '150px'
        }
    },
}));

const ResultFooter = () => {
    const classes = useStyles();

    return (
        <Grid container alignContent="space-around">
            <Grid item xs={12} sm={6} className={classes.footer}>
                <Typography color="primary" variant="body2" style={{fontWeight: "700"}}>
                    NEED TESTS FOR ANOTHER DOG?
                </Typography>
                <Button variant="contained" color="primary" component={RouterLink} to="/" size="large">
                    Start Another Order
                </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{textAlign: "center"}}>
                <Typography color="primary" variant="body2" style={{fontWeight: "700"}}>
                    HAVE QUESTIONS?
                </Typography>
                <Typography color="primary" style={{padding: "15px"}}>
                    <Link to="/help" component={RouterLink} variant="body2">
                        Read our FAQ
                    </Link>
                </Typography>
                <Typography color="primary" style={{paddingBottom: "15px"}}>
                    info@wisdomhealth.com
                </Typography>
                <Typography color="primary">
                    855-619-1884
                </Typography>
            </Grid>
        </Grid>
    )
};


export default ResultFooter;
