import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    loadingContainer: {margin: "50px", display: "flex", alignItems: "center", justifyContent: "center"}
}));

const Loading = ({message}) => {
    const styles = useStyles();
    return (
        <div className={styles.loadingContainer}>
            <CircularProgress style={{marginRight: "10px"}}/> {message || "Please wait..."}
        </div>
    );
};


export default Loading
