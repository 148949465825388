import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {ImageStyles} from "../../../styles";
import {Link as RouterLink, withRouter} from "react-router-dom";
import pathToRegexp from "path-to-regexp";

const useStyles = makeStyles(theme => ({
    footer: {
        backgroundColor: '#f0f0f0',
        '@media print': {
            display: "none"
        }
    },
    footerHeader: {
        fontWeight: 700
    }
}));

const Copyright = () => {
    return (
        <div>
            <Typography variant="body2" color="textSecondary" align="center" style={{marginBottom: '15px'}}>
                {'© '}
                {new Date().getFullYear()}{' '}
                <Link color="inherit" href="https://mars.com" underline="none">
                    Mars or Affiliates
                </Link>
                {'.'}
            </Typography>
            <Typography align="center">
                <img src="/helix-mark.svg" alt="Wisdom Panel Logo"/>
            </Typography>
        </div>
    );
};

const viewingResultsPage = (history) => {
    const re = pathToRegexp('/results/:resultId/:productId');
    const result = re.exec(history.location.pathname);
    return !!result;
};

const Footer = (history) => {
    const imgClasses = ImageStyles();
    const classes = useStyles();

    // Hide the footer on the results page, because the footer extends out of the results component
    // and shows up in the printable version
    if(viewingResultsPage(history)) {
        return (<div></div>)
    } else {

        return (
            <footer className={classes.footer}>
                <Container maxWidth="lg">
                    <Grid container spacing={4} style={{paddingTop: '50px'}}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h6" gutterBottom className={classes.footerHeader}>
                                Mission
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary" component="p">
                                The mission of the WISDOM HEALTH™ veterinary science and research is to facilitate
                                responsible pet care by enhancing the well-being and relationship between pets, pet
                                owners, breeders, shelters and veterinarians through valuable insights into pets as
                                individuals. Through the genetic testing of dogs and cats we aim to make advances in
                                science towards a better world for all pets so their owners can love them longer.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h6" gutterBottom className={classes.footerHeader}>
                                Legal
                            </Typography>
                            <List>
                                <ListItem className="no-padding">
                                    <Link href="https://www.mars.com/global/policies/privacy/pp-english" target="_blank">Privacy Policy</Link>
                                </ListItem>
                                <ListItem className="no-padding">
                                    <Link href="https://www.mars.com/global/policies/cookie/cn-english" target="_blank">Cookie Policy</Link>
                                </ListItem>
                                <ListItem className="no-padding">
                                    <Link href="https://www.mars.com/global/policies/adchoices?eid=2180" target="_blank">Ad Choice</Link>
                                </ListItem>
                                <ListItem className="no-padding">
                                    <Link href="https://www.mars.com/legal" target="_blank">Legal</Link>
                                </ListItem>
                                <ListItem className="no-padding">
                                    <Link href="https://www.mars.com/legal/ca-supply-chain-act" target="_blank">Supply Chain Transparency</Link>
                                </ListItem>
                                <ListItem className="no-padding">
                                    <Link href="https://www.mars.com/global/accessibility" target="_blank">Accessibility</Link>
                                </ListItem>
                                <ListItem className="no-padding">
                                    <Link href="https://www.mars.com/modern-slavery-act" target="_blank">Modern Slavery Act</Link>
                                </ListItem>
                                <ListItem className="no-padding">
                                    <Link href="https://www.mars.com/global" target="_blank">Mars.com</Link>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h6" gutterBottom className={classes.footerHeader}>
                                Helpful Links
                            </Typography>
                            <List>
                                <ListItem className="no-padding">
                                    <Link component={RouterLink} to="/help">Help/FAQ</Link>
                                </ListItem>
                                <ListItem className="no-padding">
                                    <Link href="https://www.optimal-selection.com/">OPTIMAL SELECTION&trade;</Link>
                                </ListItem>
                                <ListItem className="no-padding">
                                    <Link href="https://mydogdna.com/">MYDOGDNA&trade;</Link>
                                </ListItem>
                            </List>
                        </Grid>

                    </Grid>

                    <div style={{borderTop: '1px solid white', marginTop: '50px', paddingTop: '50px'}}>
                        <Grid item xs={12} style={{maxWidth: '300px', textAlign: 'center', margin: 'auto'}}>
                            <Typography component="p" style={{margin: '50px auto'}}>
                                Previously known as Optigen
                                <img className={imgClasses.image} src="/opt11_logo.gif" alt="Optigen Logo"/>
                            </Typography>
                        </Grid>
                        <Copyright/>
                    </div>
                </Container>
                <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</button>
            </footer>
        );
    }
};

export default withRouter(Footer);
