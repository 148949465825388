import * as constants from '../constants';

export const selectBreed = (breed) => {
    // Return an action
    return {
        type: constants.SELECT_BREED,
        payload: breed
    };
};

export const setStripeToken = (token) => {
    // Return an action
    return {
        type: constants.SET_STRIPE_TOKEN,
        payload: token
    };
};


export const toggleProductSelection = (testID) => {
    return {
        type: constants.TOGGLE_TEST_SELECTION,
        payload: testID
    }
}

export const clearTestSelection = (clear = true) => {
    return {
        type: constants.CLEAR_TEST_SELECTION,
        payload: clear
    }
}


export const toggleShowAllProducts = (toggle) => {
    return {
        type: constants.TOGGLE_SHOW_ALL_PRODUCTS,
        payload: toggle
    }
}

export const setOrderForm = (orderData) => {
    return {
        type: constants.SET_ORDER_FORM,
        payload: orderData
    };
};

export const setOwnerForm = (ownerData) => {
    return {
        type: constants.SET_OWNER_FORM,
        payload: ownerData
    };
};

export const setOrderProcessing = (orderProcessing) => {
    // Return an action
    return {
        type: constants.SET_ORDER_PROCESSING,
        payload: orderProcessing
    };
};

export const setDisableContineButton = (disable) => {
    // Return an action
    return {
        type: constants.SET_DISABLE_CONTINUE_BUTTON,
        payload: disable
    };
};

export const setDisablePlaceOrderButton = (disable) => {
    // Return an action
    return {
        type: constants.SET_DISABLE_PLACE_ORDER_BUTTON,
        payload: disable
    };
};

export const setOrderHash = (orderhash) => {
    // Return an action
    return {
        type: constants.SET_ORDER_HASH,
        payload: orderhash
    };
};

export const setPermission = (permission) => {
    // Return an action
    return {
        type: constants.SET_PERMISSION,
        payload: permission
    };
};


export const setAuthToken = (authToken) => {
    // Return an action
    return {
        type: constants.SET_AUTH_TOKEN,
        payload: authToken
    };
};
