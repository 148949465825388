import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const AboutPage = () => {
    return (
        <React.Fragment>
            <Typography variant="h4" gutterBottom>
                About Us
            </Typography>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" color="textSecondary" component="p">
                        WISDOM PANEL Pro provides the world's leading DNA tests for dogs. Our database includes 350+
                        breeds, types and varieties—the largest number on the market—making us not only accurate and
                        reliable, but trusted by more than 1,000,000 pet parents the world over.
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );

}

export default AboutPage;
