import React, {useEffect, useState} from "react";
import {Redirect, withRouter} from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {useAuth} from "../../../context/auth";
import api from "../../util/Api";
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorMessage from "../../util/ErrorMessage";
import {compose} from "redux";
import { connect } from 'react-redux';
import {setPermission} from '../../../actions';
import {setAuthToken} from '../../../actions';


const useStyles = makeStyles(theme => ({
    body: {
        textAlign: "center",
        marginTop: "50px",
        marginBottom: "100px"
    },
    spinner: {
        position: "absolute",
        left: 0,
        right: 0,
        top: "23px",
        margin: "auto"
    }
}));

const Login = (props) => {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [loginDisabled, setLoginDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const {setAuthTokens} = useAuth();
    const classes = useStyles();

    useEffect(() => {
        if (isLoggedIn) {
            return props.history.push("/admin");
        }

        if (userName && password && !loading) {
            return setLoginDisabled(false);
        }
        if(!loading) {
            setLoginDisabled(true);
        }
    }, [loginDisabled, userName, password, loading, isLoggedIn]);

    const onKeyDown = (e) => {
        if(e.keyCode === 13){
            postLogin();
        }
    };

    function postLogin() {
        setLoginDisabled(true);
        setLoading(true);
        setError(false);
        api.post("/api/login", {
            userName,
            password
        }).then(result => {
            if (result.status === 200) {
                setAuthTokens(result.data.authToken);
                setLoggedIn(true);
                props.setPermission(result.data.permission);
                props.setAuthToken(result.data.authToken);
            } else {
                setLoginDisabled(false);
                setLoading(false);
                setError("The user name or password provided is incorrect.");
            }
        }).catch(e => {
            setLoginDisabled(false);
            setLoading(false);
            setError("The user name or password provided is incorrect.");
        });
    }

    return (
        <main>
            { error ? (<ErrorMessage message={error}/> ) : ("")}
            <Grid container className={classes.body}>
                <Typography style={{margin: "auto", fontWeight: 600}} variant="h6" color="primary">
                    LOGIN
                </Typography>
                <Grid item xs={12}>
                    <TextField
                        required
                        name="username"
                        label="Username"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        variant="outlined"
                        onKeyDown={onKeyDown}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        label="Password"
                        type="password"
                        margin="normal"
                        value={password}
                        onKeyDown={onKeyDown}
                        onChange={(e) => setPassword(e.target.value)}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} style={{position: "relative"}}>
                    {loading ? (<CircularProgress className={classes.spinner} />) : ("") }
                    <Button variant="contained" color="primary" onClick={postLogin} size="large"
                            style={{marginTop: "15px"}} disabled={loginDisabled}>
                        Sign In
                    </Button>
                </Grid>
            </Grid>
        </main>
    );
};

const mapStateToProps = state => {
    return { };
};

export default compose(withRouter, connect(mapStateToProps,
    { setPermission, setAuthToken}
))(Login)