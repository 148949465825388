import {makeStyles} from '@material-ui/core/styles';

// @ts-ignore
const ProductStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        width: "100%"
    },
    subHeader: {
        textTransform: 'uppercase',
        fontWeight: '700 !important',
        marginBottom: 0
    },
    productTable: {
        '& div:nth-child(odd)': {
            textAlign: "right",
            paddingRight: "10px",
            color: theme.palette.primary.main,
            fontWeight: 600,
            [theme.breakpoints.down('xs')]: {
                textAlign: "left",
            }
        },
        '& div:nth-child(even)': {
            textAlign: "left"
        }
    },
    backButton: {
        marginBottom: '25px',
        display: 'inline-flex',
        alignItems: 'center',
        cursor: 'pointer',
        fontWeight: 500,
        '&:hover': {
            fontWeight: 600
        }
    },
    packageSlipBreak: {
        '@media': {
            pageBreakAfter: "always"
        }
    }
}));

export default ProductStyles;
