import React from 'react';
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import makeStyles from '@material-ui/core/styles/makeStyles';
import Link from "@material-ui/core/Link";
import userLocale from'../../util/UserLocaleLanguage';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: "18px",
        margin: "50px 0",
        [theme.breakpoints.down('xs')]: {
           fontSize: "16px",
           margin: "30px 0"
        }
    },
    logo: {
        height: "45px",
        [theme.breakpoints.down('xs')]: {
            height: "35px"
         }
    },
    bolded: {
      fontWeight: "bold",
      textDecoration: "underline"
    }
  }));

const BreedSelector = (props) => {
    const theme = useTheme();
    const styles = useStyles();
    const linkForLocale = () => {
        if (userLocale() === 'us') {
          return {
            faq: 'https://optimal-selection.com/pages/deprecated-breeder-site#faq',
            links: 'https://optimal-selection.com/pages/deprecated-breeder-site#alternatives'
          };
        } else {
          return {
            faq: 'https://mydogdna.com/pages/deprecated-breeder-site#faq',
            links: 'https://mydogdna.com/pages/deprecated-breeder-site#alternatives'
          };
        }
      };

    return (
        <Box component="span" m={1}>
            <Grid item xs={12} sm={8} style={{margin: 'auto', paddingBottom: '25px'}}>
                <div className={styles.title}>
                    <strong>
                        Important Notice: Deprecation of breeder.wisdompanel.com on July 14, 2023
                    </strong>
                </div>
                <div>
                    <p>
                        Dear Valued Customer,
                    </p>
                    <p>
                        We are reaching out to let you know that we will be discontinuing our services at breeder.wisdompanel.com on <span className={styles.bolded}>July 14, 2023.</span> We understand that this news may come as a surprise and inconvenience to you, and we want to take a moment to express our sincere regret
                        for any disruption this may cause.
                    </p>
                    <p>
                        We have carefully considered this decision and believe it is the best course of action for the long-term success of our business. As 
                        technology continues to evolve at a rapid pace, we recognize the need to make significant changes to our platform to keep up with the latest
                        trends and meet the evolving needs of our customers. We are committed to providing you with the best possible experience, which is why we 
                        have decided to shut down the Wisdom Panel Pro  site and further invest in our other breeder services Optimal Selection and My Dog DNA 
                        that will better serve your needs.
                    </p>
                    <p>
                        We know that this may cause some inconvenience, and we apologize for any disruption this may cause; we have put together a list of alternative
                        <Link href={linkForLocale().links} underline="none"> <span className={styles.bolded}>labs</span></Link> for each of the tests that will be discontinued. To minimize any inconvenience, we encourage you to complete any necessary transactions
                        on our current website within the next 60 days and submit any samples by <span className={styles.bolded}>December 15, 2023.</span> If you need help or have any questions or concerns,
                        please do not hesitate to reach out to us at breeder@wisdomhealth.com. For quick questions, we have put together some <Link href={linkForLocale().faq} underline="none"> <span className={styles.bolded}>FAQ</span>.</Link> We're here to support you throughout this transition.
                    </p>
                    <p>
                        We want to thank you for your understanding and continued support. We appreciate your loyalty, and we're confident that our new platform will
                        provide you with an even better experience.
                    </p>
                    <p>
                        Warmest regards,
                    </p>
                    <p>
                        <img className={styles.logo} src="/logo.png" alt="Wisdom Panel Logo"/>
                    </p>
                </div>
            </Grid>
        </Box>
    )
};

export default BreedSelector;



