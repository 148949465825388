import makeStyles from "@material-ui/core/styles/makeStyles";

const PaymentInputStyles = makeStyles(theme => ({
    cardField: {
        margin: "auto",
        marginTop: "15px",
        marginBottom: "10px",
        paddingBottom: "2px",
    },
    error: {
        color: theme.palette.error.main,
        paddingLeft: "15px"
    }
}));

export default PaymentInputStyles;
