import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import "react-datepicker/dist/react-datepicker.css";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { setDisableContineButton, setOrderForm } from '../../../actions';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { OrderStyles } from "../../../styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import BreedDropdown from "../breed/BreedDropdown";
import {
    ThemeProvider,
    createMuiTheme,
} from '@material-ui/core/styles';


const YourOrder = (props) => {
    let sexes = ["male", "female", "neutered", "spayed"];

    const classes = OrderStyles();
    const inputLabelRef = React.useRef(null);

    let formHistory = {};
    let errorHistory = {};
    if (sessionStorage.getItem("orderForm")) {
        formHistory = JSON.parse(sessionStorage.getItem("orderForm"));
    }
    if (sessionStorage.getItem("formError")) {
        errorHistory = JSON.parse(sessionStorage.getItem("formError"));
    }

    const [form, setValues] = useState({
        sex: formHistory.sex || '',
        dogName: formHistory.dogName || '',
        breedName: formHistory.breedName || '',
        registeredName: formHistory.registeredName || '',
        registrationId: formHistory.registrationId || '',
        microchipNumber: formHistory.microchipNumber || '',
        birthDate: formHistory.birthDate || null,
        breed: props.selectedBreed ? props.selectedBreed.breed : "",
    });

    const [formError, setErrors] = useState({
        sex: errorHistory.sex !== false,
        birthDate: errorHistory.birthDate !== false,
        dogName: errorHistory.dogName !== false,
        breedName: errorHistory.breedName !== false,
    });

    const [dirty, setDirty] = useState({
        sex: formHistory.sex || false,
        birthDate: formHistory.birthDate || false,
        dogName: formHistory.dogName || false,
        breedName: formHistory.breedName || false,
    });

    useEffect(() => {
        if (props.selectedProducts.length === 0) {
            props.history.push("/");
            return;
        }
        props.setDisableContineButton(true);
        sessionStorage.setItem("orderForm", JSON.stringify(form));
        sessionStorage.setItem("formError", JSON.stringify(formError));
        props.setOrderForm(form);
        checkErrors();
    }, [form, formError, dirty]);

    const handleOnBlur = (e) => {
        setDirty({
            ...dirty,
            [e.target.name]: true
        });
    };

    const isValidDate = (date) => {
        const temp = date.split('/');
        const d = new Date(temp[2] + '/' + temp[0] + '/' + temp[1]);
        return (d && (d.getMonth() + 1) == temp[0] && d.getDate() == Number(temp[1]) && d.getFullYear() == Number(temp[2]));
    };


    const handleInputChange = (e) => {
        if (!e) {
            return;
        }

        if (e.target.value === null) e.target.value = '';
        setValues({
            ...form,
            [e.target.name]: e.target.value
        });

        // Verify that date is valid and in the past
        if (e.target.name === 'birthDate' && e.target.value) {
            let isError = isNaN(e.target.value.getTime());
            if(!isError) {
                isError = e.target.value > new Date()
            }
            setErrors({
                ...formError,
                [e.target.name]: isError
            });
            return;
        }
        setErrors({
            ...formError,
            //date picker required is undefined and we purposely do not use required for sex (but it is) to hide the asterisk
            [e.target.name]: (e.target.required || (e.target.required === undefined)) && !e.target.value
        });
    };

    const checkErrors = () => {
        let continueDisabled = false;
        for (const key in formError) {
            if(!props.selectedBreed || props.selectedBreed.id === 'select-a-breed') {
                continueDisabled = true;
            } else if (props.selectedBreed.breed !== "Other" && key === "breedName") {
                // Skip checking this field for errors unless 'Other' breed is selected
                continue;
            }
            if (formError[key]) {
                continueDisabled = true;
            }
        }
        props.setDisableContineButton(continueDisabled);
    };
    checkErrors();

    const handleSelectChange = e => {
        e.target.required = true;
        handleInputChange(e);
    };

    const showDogInput = () => {
        if (props.selectedBreed && props.selectedBreed.breed === "Other" && props.selectedBreed.id !== 'select-a-breed') {
            return (
                <TextField
                    required
                    id="BreedName"
                    name="breedName"
                    label="Breed Name"
                    fullWidth
                    value={form.breedName}
                    onChange={handleInputChange}
                    onBlur={handleOnBlur}
                    helperText={dirty.breedName && formError.breedName ? "Please enter a breed name for your dog" : ""}
                    error={dirty.breedName && formError.breedName}
                    variant="outlined"
                    margin="normal"
                    autoFocus={true}
                />
            )
        }
    };

    return (
        <React.Fragment>
            <Typography variant="h4" align="center" color="primary" className={classes.pageHeader}>
                Your Order
            </Typography>


            <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h6" color="primary" className={classes.sectionHeader}>
                        <span>ABOUT YOUR DOG</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <BreedDropdown style={{ width: "100%" }} clearOnChange={false} />
                    {showDogInput()}
                    <TextField
                        required
                        InputLabelProps={{ required: false }}
                        id="DogName"
                        name="dogName"
                        label="Call Name"
                        fullWidth
                        autoFocus={props.selectedBreed && props.selectedBreed.breed !== "Other"}
                        value={form.dogName}
                        onChange={handleInputChange}
                        onBlur={handleOnBlur}
                        variant="outlined"
                        helperText={dirty.dogName && formError.dogName ? "Please enter a name for your dog" : ""}
                        error={dirty.dogName && formError.dogName}
                        margin="normal"
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            InputLabelProps={{ required: false }}
                            style={{ width: "100%" }}
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            margin="normal"
                            autoOk={true}
                            id="date-picker-inline"
                            label="Date of Birth (mm/dd/yyyy)"
                            name="birthDate"
                            disableFuture={true}
                            value={form.birthDate}
                            onChange={(date) => handleInputChange({ target: { name: "birthDate", value: date } }) }
                            onBlur={handleOnBlur}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            helperText={dirty.birthDate && formError.birthDate ? "Please enter a valid date" : ""}
                            error={dirty.birthDate && formError.birthDate}
                        />
                    </MuiPickersUtilsProvider>

                    <div className={classes.root}>
                        <FormControl variant="outlined" className={classes.formControl}
                            error={dirty.sex && formError.sex}
                            style={{ width: "50%", marginLeft: 0, marginRight: 0, marginTop: "15px" }}>
                            <InputLabel style={{ background: "white", paddingRight: "10px", paddingLeft: "10px", required: false }}
                                id="demo-simple-select-outlined-label">Sex</InputLabel>

                            <Select
                                ref={inputLabelRef}
                                name="sex"
                                required
                                value={form.sex}
                                onBlur={handleOnBlur}
                                onChange={handleSelectChange}
                                inputProps={{
                                    name: 'sex',
                                    id: 'sex-dropdown',
                                }}
                            >
                                {
                                    sexes.map(sex => {
                                        return (
                                            <MenuItem value={sex} key={`sex-` + sex}><span
                                                style={{ textTransform: "capitalize" }}>{sex}</span></MenuItem>
                                        );
                                    })
                                }
                            </Select>

                            <FormHelperText>{dirty.sex && formError.sex ? "Please select a gender" : ""}</FormHelperText>
                        </FormControl>
                    </div>
                    <TextField
                        id="Regname"
                        name="registeredName"
                        label="Registered name (optional)"
                        fullWidth
                        value={form.registeredName}
                        onChange={handleInputChange}
                        variant="outlined"
                        margin="normal"
                    />
                    <TextField
                        id="Regid"
                        name="registrationId"
                        label="Registration ID (optional)"
                        fullWidth
                        value={form.registrationId}
                        onChange={handleInputChange}
                        variant="outlined"
                        margin="normal"
                    />

                    <TextField
                        id="MicrochipNum"
                        name="microchipNumber"
                        label="Microchip Number (optional)"
                        fullWidth
                        value={form.microchipNumber}
                        onChange={handleInputChange}
                        variant="outlined"
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={"auto"} sm={3} />

            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return { selectedBreed: state.selectedBreed, orderForm: state.orderForm, selectedProducts: state.selectedProducts };
};

export default compose(withRouter, connect(mapStateToProps,
    { setDisableContineButton, setOrderForm }
))(YourOrder)
