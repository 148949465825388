import * as constants from '../constants';

export default (state = [], action) => {
    switch (action.type) {
        case constants.TOGGLE_TEST_SELECTION:
            // either add the payload to the list or remove it
            if (state.includes(action.payload)) {
                return state.filter((testId)=>{
                    return testId !== parseInt(action.payload)}
                )
            } else {
                return state.concat(action.payload);
            }
        case constants.CLEAR_TEST_SELECTION:
            return [];
        default:
            return state;
    }
};