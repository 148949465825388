import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from "@material-ui/core/Container";

const AboutPage = () => {
    return (
        <React.Fragment>
            <div style={{marginTop: '25px', marginBottom: '25px'}}>
                <Typography variant="h4" style={{marginTop: '10px', marginBottom: '10px'}} color="primary">
                    Cookie Policy
                </Typography>
                <div id="optanon-cookie-policy"></div>
            </div>
        </React.Fragment>
    );
};

export default AboutPage;
