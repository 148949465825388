import React from 'react';
import { connect } from 'react-redux';
import ImageStyles from "../../../styles/ImageStyles";
import { Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const HeaderImage = (props) => {
    const theme = useTheme();
    const imageClasses = ImageStyles();
    const imgUrl = "/hero-no-text.jpg";

    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));

    const getStyle = () => {
        if (!isMedium) {
            return { transform: 'scale(1.5)', transformOrigin: 'top left' };
        } else if (isMobile) {
            return { transform: 'scale(0.7)', transformOrigin: 'top right', marginLeft: '25px' };
        }
    };

    return (
        <div className={imageClasses.imageContainer}>
            <img className={imageClasses.image} src={imgUrl} alt="Header with a Kooikerhondje" />
            <div className={imageClasses.slogan} style={getStyle()}>
                <Typography variant="h4" className={imageClasses.sloganLine1} style={isMobile ? { fontSize: '1.5rem' } : {}}>Smarter</Typography>
                <Typography variant="h3" className={imageClasses.sloganLine2} style={isMobile ? { paddingLeft: 0, fontSize: '1.5rem' } : {}}>Breeding</Typography>
                <Typography variant="h5" className={imageClasses.sloganLine3} style={isMobile ? { paddingLeft: 0, fontSize: '1.5rem' } : { float:'left'}}>through genetics.</Typography>
                <Typography variant="h6" className={imageClasses.sloganLine3} style={isMobile ? { paddingLeft: 0, fontSize: '1.5rem' } : {paddingLeft: 0, float:'left'}}>&trade;</Typography>
            </div>
        </div>
    )
};


const mapStateToProps = state => {
    return { breeds: state.breeds, selectedBreed: state.selectedBreed };
};

export default connect(
    mapStateToProps, {}
)(HeaderImage);
