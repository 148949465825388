import makeStyles from "@material-ui/core/styles/makeStyles";

const FaqStyles = makeStyles({
    questionContainer: {
        marginBottom: '50px'
    },
    question: {
        fontWeight: 700,
    },
    answer: {},
    contactContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        float: 'right',
        margin: '50px'
    }
});

export default FaqStyles;
