export const FETCH_TESTS = 'FETCH_TESTS';
export const FETCH_BREEDS = 'FETCH_BREEDS';
export const SELECT_BREED = 'SELECT_BREED';
export const SET_STRIPE_TOKEN = 'SET_STRIPE_TOKEN';
export const TOGGLE_TEST_SELECTION = 'TOGGLE_TEST_SELECTION';
export const SELECTED_TESTS = 'SELECTED_TEST';
export const CLEAR_TEST_SELECTION = 'CLEAR_TEST_SELECTION';
export const TOGGLE_SHOW_ALL_PRODUCTS = 'TOGGLE_SHOW_ALL_PRODUCTS';
export const SHOW_ALL_PRODUCTS = 'SHOW_ALL_PRODUCTS';
export const SET_ORDER_FORM = 'SET_ORDER_FORM';
export const SET_OWNER_FORM = 'SET_OWNER_FORM';

export const SET_OWNER_NAME = 'SET_OWNER_NAME';
export const SET_DISABLE_CONTINUE_BUTTON = 'SET_DISABLE_CONTINUE_BUTTON';
export const SET_DISABLE_PLACE_ORDER_BUTTON = 'SET_DISABLE_PLACE_ORDER_BUTTON';
export const SET_ORDER_HASH = 'SET_ORDER_HASH';
export const SET_ORDER_PROCESSING = 'SET_ORDER_PROCESSING';
export const SET_PERMISSION = 'SET_PERMISSION';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';

