import * as constants from '../constants';

export default (state = false, action) => {
    switch (action.type) {
        case constants.SHOW_ALL_PRODUCTS:
            // initial load of tests
            // all of the tests are deselected
            return state;
        case constants.TOGGLE_SHOW_ALL_PRODUCTS:
            // return the tests with the selected test selected state toggled
            return !state;
        
        default:
            return state;
    }
};