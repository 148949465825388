import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Footer from './Footer';
import Popup from './Popup';
import {ThemeProvider} from '@material-ui/styles';
import {createMuiTheme} from '@material-ui/core/styles';
import Header from "./Header";

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#04619B',
            main: '#163860',
            dark: '#0c2242',
            contrastText: '#fff',
        },
        secondary: {
            light: '#fd7721',
            main: '#f75606',
            dark: '#cf231c',
            contrastText: '#fff',
        },
        error: {
            main: '#cf231c',
            contrastText: '#fff',
        },
        background: {
            default: '#fffff !important'
        }
    },
    typography: {
        fontFamily: [
            'Montserrat',
            'sans-serif'
        ].join(','),
    },
    overrides: {
        MuiContainer: {
            maxWidthLg: {
                maxWidth: '1024px !important'
            }
        },
        MuiButton: {
            sizeLarge: {
                padding: '15px',
                paddingLeft: '25px',
                paddingRight: '25px',
                boxShadow: '0px 4px 0 0px rgba(0, 0, 0, 0.13)',
                '&:hover': {
                    boxShadow: '0px 4px 0 0px rgba(0, 0, 0, 0.13)',
                }
            },
            root: {
                fontWeight: 800,
                textTransform: 'capitalize',
            }
        },
        MuiListItem: {
            gutters: {
                '&.no-padding': {
                    paddingLeft: '0'
                }
            }
        },
        MuiLink: {
            underlineHover: {
                fontWeight: 600,
                color: '#0c2242',
                textUnderlinePosition: 'none',
                borderBottom: '2px solid transparent',
                '&:hover': {
                    textDecoration: 'none !important',
                    borderBottom: '2px solid #0c2242',
                    '&:active': {
                        color: '#04619b',
                        borderBottom: '2px solid #04619b'
                    }
                }
            }
        },
        MuiOutlinedInput: {
            root: {
                '&$focused $notchedOutline': {
                    borderColor: "#04619B",
                }
            }
        }
    }
});

const Template = (props) => {
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Popup />
                <CssBaseline/>
                <Header/>
                <Container maxWidth="lg">
                    <main>
                        {props.children}
                    </main>
                </Container>
                <Footer/>
            </ThemeProvider>
        </React.Fragment>
    )

}

export default Template
