import * as constants from '../constants';


export default (state = {}, action) => {
    switch (action.type) {
        case constants.FETCH_BREEDS:
            return action.payload;
        default:
            return state;
    }
};
