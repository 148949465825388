import React, {useEffect} from 'react';
import api, {getProducts} from "../../util/Api";
import {Typography} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Chip from "@material-ui/core/Chip";
import { Link as RouterLink, Redirect } from "react-router-dom";
import Loading from "../misc/Loading";
import ResultListStyles from "../../../styles/ResultListStyles";
import ResultFooter from "./ResultFooter";

const ResultsList = (props) => {
    const classes = ResultListStyles();
    const orderId = props.match.params.orderId;
    const [order, setOrder] = React.useState(false);
    const [products, setProducts] = React.useState(false);
    const [authToken, setAuthToken] = React.useState(false);

    useEffect(() => {
        async function fetchData() {
            let token = props.location.search.split("=")[1];
            setAuthToken(token);
            const order = await api.get(`/api/orders/${orderId}?token=${token}`);
            const productsResults = await getProducts();

            setProducts(productsResults.filter(product => {
                return order.data[0].products.indexOf(product.id) > -1
            }));
            setOrder(order.data[0]);
        }
        fetchData();
    }, [orderId]);

    if (order) {
        if (order.authtoken !== authToken)
            return <Redirect to="/login" />;
        else {
            return (
                <div className={classes.container}>
                    <Typography component="h3" variant="h3" style={{ marginBottom: "20px" }}>
                        {order.dogname}'s Results
                </Typography>
                    <Typography component="h5" variant="h5">
                        Order ID: {order.hash}
                    </Typography>

                    <List dense>
                        {products.map(product => {
                            return (
                                <ListItem
                                    className={classes.listItem}
                                    key={product.id}>
                                    <ListItemText
                                        primary={product.name}
                                    />
                                    <ListItemSecondaryAction>
                                        <Chip
                                            label="View Results"
                                            className={classes.chip}
                                            component={RouterLink} to={{pathname: `/results/${orderId}/${product.id}`, search: `token=${order.authtoken}`}}
                                            onClick={() => { }}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        })}
                    </List>
                    <div style={{ marginTop: "50px" }}>
                        <ResultFooter />
                    </div>
                </div>
            )
        }
    } else {
        return <Loading message="Loading your order..." />
    }
};


export default ResultsList;
