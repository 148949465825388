const userLanguageSettings =
navigator.languages && navigator.languages.length
  ? navigator.languages[0]
  : navigator.language;

const userLocale = () => {
  if (userLanguageSettings === 'en-US' || userLanguageSettings === 'en-CA') {
    return 'us';
  } else {
    return 'uk';
  }
};

export default userLocale;