import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    containerStyle: {
      paddingTop: "25px",
      paddingBottom: "25px"
    },
    paragraphStyle: {
        paddingBottom: "25px"
    }
}));

const SpecialMessage = () => {
    const styles = useStyles();

    return (
        <React.Fragment>
            <div className={styles.containerStyle}>
                <Typography variant="h4" gutterBottom color="primary">
                    A message to former OptiGen, LLC customers
                </Typography>
                <Typography variant="subtitle1" component="p" className={styles.paragraphStyle}>
                    On behalf of the entire OptiGen staff, I want to express our sincere thanks for your loyal patronage
                    of
                    OptiGen these past 20+ years. It has been an honor to serve you and support your breeding programs.
                </Typography>
                <Typography variant="subtitle1" component="p" className={styles.paragraphStyle}>
                    OptiGen was one of the first laboratories to offer genetic tests for inherited diseases in dogs, and
                    in
                    the intervening decades there have been amazing technological advances in this field. The scientists
                    at
                    Wisdom Health have been among the leaders in this arena and it is with great pleasure that we at
                    OptiGen
                    have joined forces with them to help carry these services on to benefit even more dog owners and
                    breeders.
                </Typography>
                <Typography variant="subtitle1" component="p" className={styles.paragraphStyle}>
                    Unlike OptiGen’s methods of performing single tests, the Wisdom Health panels of tests (OPTIMAL
                    SELECTION™ and MYDOGDNA™) provide a powerful, efficient, and cost-effective tool for breeders to
                    assess
                    hundreds of genetic factors, including tests for most of the inherited disease mutations that
                    OptiGen
                    has offered. There are certain tests, however, that require special handling, either due to their
                    unique
                    biological makeup or due to patent and licensing constraints. These are the tests that you will find
                    available here on the à la carte platform.
                </Typography>
                <Typography variant="subtitle1" component="p" className={styles.paragraphStyle}>
                    Finally, I would like to add that, similar to the mission at OptiGen, the scientists at Wisdom
                    Health
                    are entirely committed to continuing research on inherited
                    diseases in dogs. Much like OptiGen did, the Wisdom Health team looks forward to working with the
                    critical ingredient in any of these studies—YOU, the dog breeding community. It is only with your
                    knowledge, care, and efforts that all of the advances have been achieved in developing the multitude
                    of
                    DNA tests now available for the inherited disorders that afflict dogs. With your continued
                    participation, that number will continue to grow and more tests will be made available. OptiGen’s
                    legacy
                    of working closely with the dog breeding community, with the aim of discovering genetic markers to
                    help
                    prevent inherited diseases in future litters, is in excellent hands with Wisdom Health.
                </Typography>
                <Typography variant="subtitle1" component="p" className={styles.paragraphStyle}>
                    We at OptiGen
                    wish you, the dog owners and breeders, the best in this new era.
                </Typography>
                <Typography variant="subtitle1" component="p" className={styles.paragraphStyle}>
                    Sincerely,
                </Typography>
                <Typography variant="subtitle1" component="p" className={styles.paragraphStyle}>
                    Sue Pearce-Kelling<br />
                    President and Manager, OptiGen, LLC
                </Typography>

            </div>
        </React.Fragment>
    );
};

export default SpecialMessage;
