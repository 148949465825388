import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import api, {getBreeds, getProducts} from "../../util/Api";
import {Typography} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import {Link as RouterLink} from "react-router-dom";
import Loading from "../misc/Loading";
import ResultListStyles from "../../../styles/ResultListStyles";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {setOrderForm} from '../../../actions';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Link from "@material-ui/core/Link";
import {ProductStyles} from "../../../styles";
import Divider from "@material-ui/core/Divider";
import {validateEmail, validatePhone} from "../../util/Validation";
import ErrorMessage from "../../util/ErrorMessage";
import CircularProgress from "@material-ui/core/CircularProgress";
import {CSVLink} from "react-csv";
import GetApp from '@material-ui/icons/GetApp';

const dmsTestId = 26;


const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    selectLabel: {
        background: "white",
    },
    loader: {
        position: "absolute",
        left: "0",
        right: "0",
        margin: "auto",
        top: "15px"
    }
}));


const OrderDetail = (props) => {
    const orderId = props.match.params.orderId;
    const options = {
        "autosomal recessive": [
            "Clear", "Carrier", "At Risk"
        ],
        "autosomal dominant with incomplete penetrance": [
            "Clear", "At Risk (1 copy)", "At Risk (2 copy)"
        ],
        "polygenic": [
            "Low Risk", "Moderate Risk", "High Risk", "Undetermined Risk"
        ],
        "autosomal recessive with heterozygous effect": [
            "Clear", "Carrier", "At Risk"
        ],
        "linked marker for autosomal recessive with incomplete penetrance": [
            "Homozygous Wild Type", "Heterozygous", "Homozygous Risk Variant"
        ]
    };

    let sexes = ["male", "female", "neutered", "spayed"];
    let dms0Results = ["G/G", "G/A", "A/A"];
    let dms1Results = ["ACTCCACAAA/ACTCCACAAA", "ACTCCACAAA/GACT", "GACT/GACT"];
    let dms2Results = ["002:01/002:01",
        "002:01/009:01",
        "002:01/023:01",
        "002:01/015:01",
        "002:01/037:01",
        "002:01/undetermined",
        "002:01/46:01",
        "009:01/023:01",
        "009:01/015:01",
        "009:01/037:01",
        "009:01/undetermined",
        "009:01/46:01",
        "023:01/015:01",
        "023:01/037:01",
        "023:01/undetermined",
        "023:01/46:01",
        "015:01/037:01",
        "015:01/undetermined",
        "015:01/46:01",
        "037:01/undetermined",
        "037:01/46:01",
        "undetermined/undetermined",
        "undetermined/46:01"];

    const [order, setOrder] = React.useState(false);
    const [products, setProducts] = React.useState(false);
    const [breedObjs, setBreedObjs] = React.useState([]);
    const [breeds, setBreeds] = React.useState([]);
    const [disabled, setDisabled] = React.useState(true);
    const [saveDisabled, setSaveDisabled] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const inputLabelRef = React.useRef(null);
    const [csvData, setCSVData] = React.useState([]);

    const listClasses = ResultListStyles();
    const classes = useStyles();
    const styles = ProductStyles();


    const [form, setValues] = useState({
        result: '',
        sex: '',
        dogName: '',
        ownerFirstName: '',
        ownerLastName: '',
        phone: '',
        email: '',
        registeredName: '',
        registrationId: '',
        microchipNumber: '',
        birthDate: new Date(),
        breed: ''
    });

    const [formError, setErrors] = useState({
        sex: false,
        dogName: false,
        ownerFirstName: false,
        ownerLastName: false,
        phone: false,
        email: false,
    });

    useEffect(() => {
        async function fetchData() {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.authToken
            };

            try {
                const order = await api.get(`/api/orders/${orderId}`, {
                    headers: headers
                });

                const productsResults = await getProducts();
                setProducts(productsResults.filter(product => {
                    return order.data[0].products.indexOf(product.id) > -1
                }));
                setOrder(order.data[0]);

                const breedObjs = await getBreeds();
                setBreedObjs(breedObjs);

                let breedsList = breedObjs.map(breed => {
                    return breed.breed;
                });

                setBreeds(breedsList);

                const testResults = await api.get(`/api/results/${orderId}`, {
                    headers: headers
                });

                let formValues = {};

                testResults.data.map((testResult) => {
                    if (testResult.testPerformed === dmsTestId && testResult.result) {
                        let dmsresults = testResult.result.split(";");
                        formValues["dmsResult0"] = dmsresults[0];
                        formValues["dmsResult1"] = dmsresults[1];
                        formValues["dmsResult2"] = dmsresults[2];
                    } else {
                        formValues["result" + order.data[0].products.indexOf(testResult.testPerformed)] = testResult.result;
                    }
                    return testResult;
                });

                const breed = breedObjs.find(obj => obj.id === order.data[0].breed).breed;

                formValues["breed"] = breed;
                formValues["dogName"] = order.data[0].dogname;
                formValues["birthDate"] = order.data[0].birthdate;
                formValues["sex"] = order.data[0].sex;
                formValues["registeredName"] = order.data[0].registeredname;
                formValues["registrationId"] = order.data[0].registrationid;
                formValues["microchipNumber"] = order.data[0].microchipnumber;
                formValues["ownerFirstName"] = order.data[0].ownerfirstname;
                formValues["ownerLastName"] = order.data[0].ownerlastname;
                formValues["phone"] = order.data[0].phone;
                formValues["email"] = order.data[0].email;
                setValues(formValues);
                props.setOrderForm(form);

                let mappedProducts = [];
                productsResults.forEach(product => {
                    if(order.data[0].products.indexOf(product.id) > -1) {
                        mappedProducts.push("X")
                    } else {
                        mappedProducts.push(" ")
                    }
                });

                const productNameList = productsResults.map(product => {
                   return product.name;
                });

                let data = [
                    ["orderId", "orderDate", "breed", "dogName", "birthDate", "sex", "registeredName", "registrationId", "microchipNumber", "ownerName", "phone", "email"],
                    [orderId, order.data[0].orderdate, breed, order.data[0].dogname, order.data[0].birthdate, order.data[0].sex, order.data[0].registeredname, order.data[0].registrationid, order.data[0].microchipnumber, `${order.data[0].ownerfirstname} ${order.data[0].ownerlastname}`, order.data[0].phone, order.data[0].email]
                ];

                data[0] = data[0].concat(productNameList);
                data[1] = data[1].concat(mappedProducts);
                setCSVData(data);
            } catch (e) {
                console.error(e);
                setError(e);
            }
        }

        if (props.permission === "read/write") {
            setDisabled(false);
            checkErrors();
        }

        if (!order && !products) {
            fetchData();
        }
    }, [orderId, form, formError]);

    async function updateResult(result) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.authToken
        }

        return await api.put(`/api/results/${orderId}`, result, {
            headers: headers
        });
    }

    async function createResult(result) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.authToken
        }

        return await api.post(`/api/results/createresult`, result, {
            headers: headers
        });
    }

    async function updateOrder(order) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.authToken
        }

        return await api.put(`/api/orders/${orderId}`, order, {
            headers: headers
        });
    }

    async function finalizeOrder(order) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.authToken
        }

        return await api.put(`/api/results/${orderId}/finalize`, null, {
            headers: headers
        });
    }

    const handleSave = (e) => {
        setSaveDisabled(true);
        setSaving(true);
        const updates = products.map(async (product, index) => {

            let testResult = form['result' + index];
            if (product.id === dmsTestId && form['dmsResult0'] && form['dmsResult1'] && form['dmsResult2']) {
                testResult = `${form['dmsResult0']};${form['dmsResult1']};${form['dmsResult2']}`;
            }

            const result = {
                "result": testResult,
                "testCompleted": new Date().toLocaleDateString(),
                "testPerformed": product.id,
                "reportDate": new Date().toLocaleDateString()
            };

            await updateResult(result).then((res) => {

            }).catch(function (res) {
                // Create result if doesnt exist
                if (res.response && res.response.status === 404) {
                    result["hash"] = orderId;
                    createResult(result).then((res) => {
                    }).catch(function (res) {
                        console.error("createResult error response: ", res.response);
                        setSaveDisabled(false);
                        setSaving(false);
                        setError(res.response.data.message);
                    });
                }
            });
        });

        // Wait for order update until tests are updated
        const emailSent = order.emailsent;
        Promise.all(updates).then(() => {
            let order = {
                breed: breedObjs.find(obj => obj.breed === form['breed']).id,
                dogName: form['dogName'],
                birthDate: form['birthDate'],
                sex: form['sex'],
                registeredName: form['registeredName'],
                registrationId: form['registrationId'],
                microchipNumber: form['microchipNumber'],
                ownerFirstName: form['ownerFirstName'],
                ownerLastName: form['ownerLastName'],
                phone: form['phone'],
                email: form['email'],
                billingAddress: form['billingAddress']
            };

            updateOrder(order).then((updateOrderRes) => {
                if (emailSent) {
                    setSaveDisabled(false);
                    setSaving(false);
                    return;
                }
                finalizeOrder(order).then(res => {
                    if (res.data.emailResult) {
                        setOrder({
                            ...order,
                            emailsent: true,
                            emailsenton: new Date().toLocaleDateString(),
                            emailsentto: order.email
                        });
                    }
                    setSaveDisabled(false);
                    setSaving(false);
                }, err => {
                    setError(err);
                    setSaveDisabled(false);
                    setSaving(false);
                })
            }).catch(function (res) {
                console.error("updateOrder error response: ", res.response);
                setSaveDisabled(false);
                setSaving(false);
                setError(res.response);
            });
        });
    };

    const handleSelectChange = (e, product) => {
        e.target.required = true;
        handleInputChange(e, product);
    };

    const handleInputChange = (e, product) => {
        if (!e) {
            return;
        }
        setValues({
            ...form,
            [e.target.name]: e.target.value
        });
        setErrors({
            ...formError,
            [e.target.name]: e.target.required && !e.target.value
        });
    };

    const checkErrors = () => {
        let saveDisabled = false;
        for (const key in formError) {
            if (formError[key]) {
                saveDisabled = true;
            }
        }
        setSaveDisabled(saveDisabled);
    };

    const handlePhoneChange = e => {
        handleInputChange(e);
        setErrors({
            ...formError,
            [e.target.name]: !(e.target.value && validatePhone(e.target.value))
        });
    };

    const handleEmailChange = e => {
        handleInputChange(e);
        setErrors({
            ...formError,
            [e.target.name]: !(e.target.value && validateEmail(e.target.value))
        });
    };

    const getEmailHelperText = () => {
        if (formError.email && !order.emailsenton) {
            return "Please enter a valid email";
        } else if (!formError.email && order.emailsent) {
            return `Email sent on: ${order.emailsenton} to ${order.emailsentto}`
        }
        return "";
    };

    const resultSelect = (product, index) => {
        if (product.id === dmsTestId) {
            return (
                <div style={{display: "flex", justifyContent: "space-between", marginTop: "15px"}}>
                    <FormControl variant="outlined" style={{width: "30%"}} disabled={disabled}>
                        <InputLabel className={classes.selectLabel}>DMS A</InputLabel>
                        <Select
                            ref={inputLabelRef}
                            name="dmsResult0"
                            value={form['dmsResult0'] ? form['dmsResult0'] : ""}
                            onChange={(e) => handleSelectChange(e, "DMS A")}
                            inputProps={{
                                name: "dmsResult0",
                                id: 'dmsresult0-dropdown',
                            }}
                        >
                            {
                                dms0Results.map((dmsresult, index) => {
                                    return (
                                        <MenuItem value={dmsresult} key={`dmsresult-` + index}><span
                                            style={{textTransform: "capitalize"}}>{dmsresult}</span></MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" style={{width: "30%"}} disabled={disabled}>
                        <InputLabel className={classes.selectLabel}>DMS B</InputLabel>
                        <Select
                            ref={inputLabelRef}
                            name="dmsResult1"
                            value={form['dmsResult1'] ? form['dmsResult1'] : ""}
                            onChange={(e) => handleSelectChange(e, "DMS B")}
                            inputProps={{
                                name: "dmsResult1",
                                id: 'dmsresult1-dropdown',
                            }}
                        >
                            {
                                dms1Results.map((dmsresult, index) => {
                                    return (
                                        <MenuItem value={dmsresult} key={`dmsresult-` + index}><span
                                            style={{textTransform: "capitalize"}}>{dmsresult}</span></MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" style={{width: "30%"}} disabled={disabled}>
                        <InputLabel className={classes.selectLabel}>DMS C</InputLabel>
                        <Select
                            ref={inputLabelRef}
                            name="dmsResult2"
                            value={form['dmsResult2'] ? form['dmsResult2'] : ""}
                            onChange={(e) => handleSelectChange(e, "DMS C")}
                            inputProps={{
                                name: "dmsResult2",
                                id: 'dmsresult2-dropdown',
                            }}
                        >
                            {
                                dms2Results.map((dmsresult, index) => {
                                    return (
                                        <MenuItem value={dmsresult} key={`dmsresult-` + index}><span
                                            style={{textTransform: "capitalize"}}>{dmsresult}</span></MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
            )
        } else {
            return (
                <FormControl variant="outlined" disabled={disabled} style={{width: "100%", marginTop: "15px"}}>
                    <InputLabel className={classes.selectLabel}>Results</InputLabel>

                    <Select
                        ref={inputLabelRef}
                        name={'result' + index}
                        value={form['result' + index] ? form['result' + index] : ""}
                        onChange={(e) => handleSelectChange(e, product)}
                        inputProps={{
                            name: 'result' + index,
                            id: 'result-dropdown',
                        }}
                    >
                        {
                            options[product.inheritancemode.toLowerCase()].map(result => {
                                return (
                                    <MenuItem value={result} key={`result-` + result}>{result}</MenuItem>
                                );
                            })
                        }
                    </Select>

                </FormControl>
            )
        }
    };

    if (order) {
        return (
            <div className={listClasses.container}>

                <Link
                    className={styles.backButton}
                    underline="none"
                    component={RouterLink}
                    to={`/admin`}>
                    <KeyboardBackspaceIcon/>
                    Back
                </Link>

                {error ? (<ErrorMessage message={error}/>) : ("")}

                <Grid container>
                    <Grid item xs={9}>
                        <Typography component="h5" variant="h5" style={{marginBottom: "50px"}}>
                            Order ID: {order.hash}
                        </Typography>
                    </Grid>

                    <Grid item xs={3}>
                        {props.permission === "read_lab_data" ? (
                                <CSVLink data={csvData} style={{textDecoration: "none"}}
                                         filename={`order-${order.hash}-export.csv`}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                    >
                                        Download CSV
                                        <GetApp/>
                                    </Button>
                                </CSVLink>)
                            : ("")}
                    </Grid>

                </Grid>

                <Grid container justify="space-between" alignItems="center"
                      style={{marginTop: "15px", marginBottom: "15px"}}>
                    <Grid item xs={4}>
                        Date Ordered
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="subtitle1">
                            {order.orderdate}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                </Grid>

                <div>
                    {products.map((product, index) => (
                        <Grid container justify="space-between" key={index} alignItems="center">
                            <Grid item xs={4} style={{paddingRight: "5px"}}>
                                {product.name}
                            </Grid>
                            <Grid item xs={5}>
                                {resultSelect(product, index)}
                            </Grid>
                            <Grid item xs={3} style={{textAlign: "center", marginTop: "15px"}}>
                                <Chip
                                    label="View Results"
                                    className={listClasses.chip}
                                    component={RouterLink} to={`/results/${orderId}/${product.id}`}
                                    onClick={() => {
                                    }}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </div>

                <Divider style={{marginTop: "25px", marginBottom: "25px"}}/>

                <Grid container justify="space-between">
                    <Grid item xs="auto" sm={4}>
                        <Typography variant="h6" style={{marginTop: "15px"}}>Dog Information</Typography>
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <div className={classes.root}>
                            <FormControl variant="outlined" className={classes.formControl}
                                         error={formError.breed}
                                         style={{width: "100%", marginLeft: 0, marginRight: 0, marginTop: "15px"}}
                                         disabled={disabled}>
                                <InputLabel style={{background: "white", paddingRight: "10px", paddingLeft: "10px"}}
                                            id="demo-simple-select-outlined-label" required>Breed</InputLabel>

                                <Select
                                    ref={inputLabelRef}
                                    name="breed"
                                    required
                                    value={form.breed}
                                    onChange={handleSelectChange}
                                    inputProps={{
                                        name: 'breed',
                                        id: 'breed-dropdown',
                                    }}
                                >
                                    {
                                        breeds.map(breed => {
                                            return (
                                                <MenuItem value={breed} key={`breed-` + breed}><span
                                                    style={{textTransform: "capitalize"}}>{breed}</span></MenuItem>
                                            );
                                        })
                                    }
                                </Select>

                            </FormControl>
                        </div>
                        <TextField
                            required
                            id="DogName"
                            name="dogName"
                            label="Name"
                            fullWidth
                            value={form.dogName}
                            onChange={handleInputChange}
                            variant="outlined"
                            helperText={formError.dogName ? "Please enter a name for your dog" : ""}
                            error={formError.dogName}
                            margin="normal"
                            disabled={disabled}
                        />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{width: "100%"}}
                                disableToolbar
                                inputVariant="outlined"
                                format="MM/dd/yyyy"
                                margin="normal"
                                variant="inline"
                                autoOk={true}
                                disableFuture={true}
                                id="date-picker-inline"
                                label="Date of Birth"
                                name="birthDate"
                                value={form.birthDate}
                                onChange={(date) => handleInputChange({target: {name: "birthDate", value: date}})}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                disabled={disabled}
                            />
                        </MuiPickersUtilsProvider>
                        <div className={classes.root}>
                            <FormControl variant="outlined" className={classes.formControl}
                                         error={formError.sex}
                                         style={{width: "50%", marginLeft: 0, marginRight: 0, marginTop: "15px"}}
                                         disabled={disabled}>
                                <InputLabel className={classes.selectLabel}
                                            id="demo-simple-select-outlined-label" required>Sex</InputLabel>

                                <Select
                                    ref={inputLabelRef}
                                    name="sex"
                                    required
                                    value={form.sex}
                                    onChange={handleSelectChange}
                                    inputProps={{
                                        name: 'sex',
                                        id: 'sex-dropdown',
                                    }}
                                >
                                    {
                                        sexes.map(sex => {
                                            return (
                                                <MenuItem value={sex} key={`sex-` + sex}><span
                                                    style={{textTransform: "capitalize"}}>{sex}</span></MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <TextField
                            id="Regname"
                            name="registeredName"
                            label="Registered name"
                            fullWidth
                            value={form.registeredName}
                            onChange={handleInputChange}
                            variant="outlined"
                            margin="normal"
                            disabled={disabled}
                        />
                        <TextField
                            id="Regid"
                            name="registrationId"
                            label="Registration ID"
                            fullWidth
                            value={form.registrationId}
                            onChange={handleInputChange}
                            variant="outlined"
                            margin="normal"
                            disabled={disabled}
                        />
                        <TextField
                            id="MicrochipNum"
                            name="microchipNumber"
                            label="Microchip Number"
                            fullWidth
                            value={form.microchipNumber}
                            onChange={handleInputChange}
                            variant="outlined"
                            margin="normal"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item sm={3} xs="auto"/>

                    <Grid item xs="auto" sm={4} style={{marginTop: "50px"}}>
                        <Typography variant="h6" style={{marginTop: "15px"}}>Owner Information</Typography>
                    </Grid>
                    <Grid item xs={12} sm={5} style={{marginTop: "50px"}}>
                        <TextField
                            required
                            id="OwnerFirstName"
                            name="ownerFirstName"
                            label="First Name"
                            fullWidth
                            value={form.ownerFirstName}
                            onChange={handleInputChange}
                            helperText={formError.ownerFirstName ? "Please enter a first name" : ""}
                            variant="outlined"
                            error={formError.ownerFirstName}
                            margin="normal"
                            disabled={disabled}
                        />
                        <TextField
                            required
                            id="ownerLastName"
                            name="ownerLastName"
                            label="Last Name"
                            fullWidth
                            value={form.ownerLastName}
                            onChange={handleInputChange}
                            helperText={formError.ownerLastName ? "Please enter a last name" : ""}
                            variant="outlined"
                            error={formError.ownerLastName}
                            margin="normal"
                            disabled={disabled}
                        />
                        <TextField
                            required
                            id="Phone"
                            name="phone"
                            label="Phone"
                            fullWidth
                            value={form.phone}
                            onChange={handlePhoneChange}
                            helperText={formError.phone ? "Please enter a valid phone number" : ""}
                            variant="outlined"
                            error={formError.phone}
                            margin="normal"
                            disabled={disabled}
                        />
                        <TextField
                            required
                            id="Email"
                            name="email"
                            label="Email"
                            fullWidth
                            autoComplete="email"
                            onChange={handleEmailChange}
                            value={form.email}
                            variant="outlined"
                            helperText={getEmailHelperText()}
                            error={formError.email}
                            margin="normal"
                            disabled={disabled}
                        />
                    </Grid>

                    <Grid item sm={3} xs="auto"/>

                    {disabled ? ("") : (
                        <Grid item xs={12} style={{textAlign: "center", position: "relative"}}>
                            {saving ? <CircularProgress className={classes.loader}/> : ("")}
                            <Button variant="contained" color="primary" onClick={handleSave} size="large"
                                    className={classes.button} disabled={saveDisabled}>
                                Save Results
                            </Button>

                            {error ? (<ErrorMessage message={error}/>) : ("")}

                        </Grid>
                    )}
                </Grid>

            </div>
        )
    } else if (error) {
        return <div style={{margin: "50px auto"}}><ErrorMessage
            message="There was an error loading your order. Please try again in a minute" showHelp={true}/></div>
    } else {
        return <Loading message="Loading your order..."/>
    }
};

const mapStateToProps = state => {
    return {
        selectedBreed: state.selectedBreed,
        orderForm: state.orderForm,
        permission: state.permission,
        authToken: state.authToken
    };
};

export default compose(withRouter, connect(mapStateToProps,
    {setOrderForm}
))(OrderDetail)
