import {makeStyles} from '@material-ui/core/styles';

const OrderStyles = makeStyles(theme => ({
    pageHeader: {
        fontWeight: 700,
        fontSize: "24px",
        letterSpacing: "0.7px",
        marginTop: "35px",
        marginBottom: "25px"
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    sectionHeader: {
        fontWeight: 600,
        marginTop: "10px",
        fontSize: "14px",
        letterSpacing: "0.4px",
        textAlign: "right",
        [theme.breakpoints.down('xs')]: {
            borderBottom: "2px solid",
            borderColor: theme.palette.primary.main,
            lineHeight: "0.1em",
            textAlign: "center",
            '& span': {
                margin: "10px",
                padding: "0 10px",
                backgroundColor: "#fff"
            }
        },
    },
    testConfirmation: {
        fontWeight: 500,
        fontSize: "16px",
        color: "#212121",
        paddingRight: "30px"
    },
    testConfirmationPrice: {
        fontWeight: 400,
        fontSize: "14px",
        color: "#666"
    },
    aside: {
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#6a6a6a"
    }
}));

export default OrderStyles;
