import React, { useEffect, useState } from "react";
import TextField from '@material-ui/core/TextField';
import api from "../../util/Api";
import ResultListStyles from "../../../styles/ResultListStyles";
import Chip from "@material-ui/core/Chip";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Container from "@material-ui/core/Container";
import ErrorMessage from "../../util/ErrorMessage";
import { CircularProgress } from "@material-ui/core";
import { validateEmail } from "../../util/Validation";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { compose } from "redux";
import { connect } from 'react-redux';


const OrderSearch = (props) => {
    const classes = ResultListStyles();
    const [search, setSearch] = useState("");
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState(false);
    const [searching, setSearching] = useState(false);


    async function searchOrder() {
        let response = "";
        setError(false);
        setSearching(true);
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.authToken
            }
            if (validateEmail(search)) {
                response = await api.get("/api/orders?email=" + search, {
                    headers: headers
                });
                setOrders(response.data);
            } else if (search.length === 7) {
                response = await api.get("/api/orders/" + search, {
                    headers: headers
                });
                setOrders(response.data);
            } else {
                setOrders([]);
                setSearching(false);
                return setError("No valid email address or order ID");
            }

            setSearching(false);
            if (response.data.length === 0) {
                return setError("No orders found");
            }
            return setOrders(response.data);
        } catch (e) {
            setError("Something went wrong searching for orders.");
            setOrders([]);
            setSearching(false);
        }
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            searchOrder();
        }
    };

    if (orders.length === 1) {
        return <Redirect to={`/admin/order/${orders[0].hash}`} />;
    }

    const viewOrder = order => {
        props.history.push(`/admin/order/${order.hash}`)
    };

    return (
        <Container maxWidth="md"
            style={{ textAlign: "center", minHeight: "200px", paddingTop: "25px", marginBottom: "25px" }}>

            <TextField
                required
                id="search"
                name="search"
                label="orderId or email"
                autoComplete="current-search"
                value={search}
                style={{ width: "50%" }}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={onKeyDown}
                variant="outlined"
                margin="normal"
            />
            <div style={{ position: "relative", display: "inline-block" }}>
                {searching ? (<CircularProgress
                    style={{ position: "absolute", margin: "auto", left: "10px", right: 0, top: "23px" }} />) : ("")}
                <Button variant="contained" color="primary" onClick={searchOrder} size="large" disabled={searching}
                    style={{ marginTop: "16px", marginLeft: "15px" }}>
                    Search
                </Button>
            </div>

            {error ? (<ErrorMessage message={error} />) : ("")}

            {orders.length > 0 ? (
                <Table className={classes.table} aria-label="Search results table" style={{ marginTop: "25px" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Order ID</TableCell>
                            <TableCell>Owner Name</TableCell>
                            <TableCell>Dog Name</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Products</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {orders.map(row => (
                            <TableRow key={row.id} hover onClick={() => { viewOrder(row) }} style={{ cursor: "pointer" }}>
                                <TableCell>{row.hash}</TableCell>
                                <TableCell>{row.ownername}</TableCell>
                                <TableCell>{row.dogname}</TableCell>
                                <TableCell>{row.phone}</TableCell>
                                <TableCell>{row.products ? row.products.length : ""}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : ("")}
        </Container>
    )
};


const mapStateToProps = state => {
    return { permission: state.permission, authToken: state.authToken };
};

export default compose(withRouter, connect(mapStateToProps,
    {}
))(OrderSearch)
